const Info = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center p-8 text-center">
            <div className="max-w-6xl mx-auto mt-12 md:mt-0">
                <h1 className="text-4xl md:text-5xl font-bold mb-8 text-[#1D63FF]">
                    Qui sommes-nous ?
                </h1>
                <p className="text-lg md:text-xl mb-8 leading-relaxed text-gray-600">
                    Nous sommes deux experts en conseil pour la création d’entreprise. Après un cursus scolaire dans le domaine de la comptabilité et de la finance, nous avons tous deux travaillés pendant plus de 10 années dans plusieurs cabinets d’expertise comptable.
                </p>
                <p className="text-lg md:text-xl mb-8 leading-relaxed text-gray-600">
                    Nous avons ainsi acquis une expertise dans le domaine de la création et le conseil en gestion d’entreprise. Au cours de notre parcours, nous avons pu constater que malheureusement le créateur d’entreprise n'est pas assez accompagné sur la forme juridique optimale lors de la création, qui est le premier choix primordial pour bien se lancer dans le monde de l'entrepreneuriat.
                </p>
                <p className="text-lg md:text-xl mb-8 leading-relaxed text-gray-600">
                    Nous avons donc un jour eu la volonté de remédier à cela, en nous spécialisant dans les conseils à la création. C’est suite à cela que nous avons créé cet outil pour les aider. C’est ainsi qu’est né le site internet <strong className="text-[#1D63FF]">maformejuridique.fr</strong>.
                </p>
            </div>
        </div>
    );
};

export default Info;
