import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { Helmet } from "react-helmet";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ShimmerButton from '../components/magicui/shimmer-button';
import { CookieBanner } from '../components/CookieBanner';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-78TF44GTX6');
ReactGA.send({ hitType: 'pageview', page: 'window.location.pathname' });

const Homes = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 1024 && window.innerWidth < 1440);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1440);
  const { scrollYProgress } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShowCookieBanner(true);
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieBanner(false);
  };

  const handleRejectCookies = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowCookieBanner(false);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/Form');
    }, 1000);
  };

  const handleBudgetPrevisionnelClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/Form');
    }, 1000);
  };

  const handleClickStatus = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/status');
    }, 1000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsLaptop(window.innerWidth >= 1024 && window.innerWidth < 1440);
      setIsDesktop(window.innerWidth >= 1440);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {showCookieBanner && <CookieBanner onAccept={handleAcceptCookies} onReject={handleRejectCookies} />}
      <Helmet>
        {/* Helmet info */}
      </Helmet>
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen ">
          <Loader />
        </div>
      ) : (
        <>
          {isMobile && (
            <motion.div className="progress-bar" style={{ scaleX, originX: 0, scaleY: 2, backgroundColor: "#1D63FF" }} />
          )}
          <div className="overflow-hidden">
            <div className={`flex flex-col ${isTablet ? 'items-center' : 'md:flex-row items-center md:items-start'} md:pt-10 mx-4 md:mx-10`}>
              <div id="home" className="flex flex-col justify-center items-center md:items-start w-full md:w-3/4 px-8 mt-12 relative">
                <h1 className={`text-black mt-20 ${isDesktop ? 'text-5xl' : isLaptop ? 'text-4xl' : 'text-3xl'} font-bold text-center md:text-left`}>
                  Vous réfléchissez à la création de votre entreprise?
                </h1>
                <br />
                <p className={`text-black ${isDesktop ? 'text-3xl' : isLaptop ? 'text-xl' : 'text-lg'} text-center md:text-left -mt-6`}>
                  Grâce à notre expertise nous vous accompagnons dans :
                  <br />

                  <div className="flex items-center lg:mt-0 relative icon-size1">
                    <div className='icon-size1 font-semibold text-black mt-12'>
                      🔵 Le choix de la forme juridique
                    </div>
                  </div>
                  <div className="flex items-center relative text-black">
                    <div className='icon-size2 text-black font-semibold'>
                      🔵 La réalisation du budget prévisionnel
                    </div>
                  </div>
                  <div className="flex items-center relative">
                    <div className='icon-size2 text-black font-semibold'>
                      🔵 La rédaction de vos statuts
                    </div>
                  </div>

                </p>
                <div className={`flex flex-col items-center justify-center my-4 ${isDesktop ? 'lg:ml-0' : isLaptop ? 'lg:ml-36' : 'lg:ml-48'} ${isDesktop ? 'text-4xl' : isLaptop ? 'text-xl' : 'text-lg'} -mt-0 lg:-mt-36`}>
                  <div className='text-xl text-center text-[#4c7bcb] mt-6 lg:mt-[10rem] lg:max-w-3xl'>
                    <p className={`lg:text-2xl ${isDesktop ? 'text-4xl' : isLaptop ? 'text-xl' : 'text-lg'} bloc2-accueil text-center lg:text-center`}>
                      Trouvez ou confirmez la forme juridique la plus adaptée
                      <br />
                      à votre projet, à votre situation personnelle et à vos besoins
                      <br />
                      grâce à notre SIMULATEUR!
                    </p>
                  </div>

                  <div className='mt-6'>
                    <ShimmerButton onClick={handleClick}>Commencer la simulation</ShimmerButton>
                  </div>
                  <div className={`text-black ${isDesktop ? 'text-xl' : isLaptop ? 'text-lg' : 'text-base'} text-center mt-2`}>
                    <p className="inline-block" style={{ whiteSpace: 'nowrap' }}>
                      A l'issue de cette simulation, vous pourrez :
                    </p>
                    <ul className={`list-inside list-disc text-left ${isDesktop ? 'pl-32' : isLaptop ? 'pl-24' : 'pl-8'}`}>
                      <li>Obtenir votre Budget Prévisionnel issu de la simulation</li>
                      <li>Prendre rendez-vous avec un expert en création</li>
                      <li>Aller plus loin et rédiger vos Statuts</li>
                    </ul>
                  </div>



                </div>
              </div>
              <img
                src='/assets/Illust.svg'
                alt="home"
                className={`lg:ml-auto lg:mt-20 lg:mr-0 rounded-lg ${isDesktop ? 'w-[42vw]' : isLaptop ? 'w-[30vw]' : 'w-full'} ${isMobile ? 'order-last mt-4' : ''}`}
                style={{ display: 'block', marginRight: 0, position: isMobile ? 'static' : 'absolute', right: 0 }}
              />
            </div>

          </div>
        </>
      )}
    </>
  );
};

export default Homes;
