import React, { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ShimmerButton from '../components/magicui/shimmer-button';

const Contact = () => {
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    const handleInvalid = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        (e.target as HTMLInputElement).setCustomValidity("Champ obligatoire");
    };

    const handleInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        (e.target as HTMLInputElement).setCustomValidity("");
    };

    const [form, setForm] = useState({
        name: "",
        prenom: "",
        email: "",
        phone: "",
        message: "",
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const isMobile = width < 768;
            setIsMobile(isMobile);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch(' https://fbconseil-c706cd2b1837.herokuapp.com/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: form.prenom,
                    name: form.name,
                    email: form.email,
                    phone: form.phone,
                    message: form.message,
                }),
            });

            const result = await response.json();

            if (result.success) {
                setForm({
                    name: "",
                    prenom: "",
                    email: "",
                    phone: "",
                    message: "",
                });

                confirmAlert({
                    title: 'Merci pour votre message',
                    message: 'Nous reviendrons vers vous dès que possible.',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                navigate('/');
                            }
                        }
                    ]
                });
            } else {
                console.error('Failed to send email:', result.message);
                alert("Ah, quelque chose s'est mal passé. Veuillez réessayer.");
            }
        } catch (error) {
            console.error('Error:', error);
            alert("Ah, quelque chose s'est mal passé. Veuillez réessayer.");
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className={`overflow-hidden flex flex-col items-center justify-center min-h-[calc(100vh-64)] ${isMobile ? 'py-6 pt-16 mb-16' : 'py-28 pt-32'}`}>
            <main className="flex flex-col items-center justify-center w-full flex-1 px-20 text-center">
                <h1 className="text-4xl font-bold mb-10 text-black">
                    Vous souhaitez nous contacter :
                </h1>
                <form className="w-full max-w-md" onSubmit={handleSubmit}>
                    <div className="input-containers mb-4">
                        <label className='label_contact block' htmlFor="name">Nom</label>
                        <input
                            className="input_contact w-full px-2 py-1"
                            type="text"
                            id="name"
                            name="name"
                            required
                            placeholder=" "
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-containers mb-4">
                        <label className='label_contact block' htmlFor="prenom">Prénom</label>
                        <input
                            className="input_contact w-full px-2 py-1"
                            type="text"
                            id="prenom"
                            name="prenom"
                            required
                            placeholder=" "
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-containers mb-4">
                        <label className='label_contact block' htmlFor="email">Email</label>
                        <input
                            className="input_contact w-full px-2 py-1"
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder=" "
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-containers mb-4">
                        <label className='label_contact block' htmlFor="phone">Téléphone</label>
                        <input
                            className="input_contact w-full px-2 py-1"
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder=" "
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-containers mb-4">
                        <textarea
                            className="input_contact w-full"
                            id="message"
                            name="message"
                            required
                            placeholder="Votre Message"
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button className="btn_contact" type="submit">
                        <ShimmerButton>
                            Envoyer
                        </ShimmerButton>
                    </button>
                </form>
            </main>
            <div className="flex flex-col lg:flex-row items-center justify-center mt-10 max-w-5xl mx-auto">
                <img src='/img/collegues.jpg' alt="collegues" className="w-full lg:w-[25rem] h-auto lg:h-[20rem] lg:mr-6 mb-6 lg:mb-0" />
                <div className="text-left lg:ml-8 lg:max-w-2xl">
                    <p>Nous sommes deux experts en conseil pour la création d’entreprise. Après un cursus scolaire dans le domaine de la comptabilité et de la finance, nous avons tous deux travaillés pendant plus de 10 années dans plusieurs cabinets d’expertise comptable.</p> <br />
                    <p>Nous avons ainsi acquis une expertise dans le domaine de la création et le conseil en gestion d’entreprise. Au cours de notre parcours, nous avons pu constater que malheureusement le créateur d’entreprise n'est pas assez accompagné sur la forme juridique optimale lors de la création, qui est le premier choix primordial pour bien se lancer dans le monde de l'entrepreneuriat.</p>  <br />
                    <p>Nous avons donc un jour eu la volonté de remédier à cela, en nous spécialisant dans les conseils à la création. C’est suite à cela que nous avons créé cet outil pour les aider. C’est ainsi qu’est né le site internet maformejuridique.fr.</p>
                </div>
            </div>
        </div>
    );
};

export default Contact;
