import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import RGPDContent from './RGPDContent'; // Importation du contenu RGPD

const Footer: React.FC = () => {
  const [, setIsDesktop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isDesktop = width >= 768; // Taille minimale pour un écran de bureau
      setIsDesktop(isDesktop);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSimulationClick = () => {
    setTimeout(() => {
      navigate('/Form'); 
    }, 1000);
  };

  return (
    <div className="relative py-6 footer-container mb-4">
      <hr className="border-gray-400 w-[50vw] mx-auto" />
      <div className="mt-6 flex justify-center space-x-12">
        <Link className="text-xl font-bold text-gray-700 footer-text hover:underline" to="/">
          Accueil
        </Link>
        <button 
          className="text-xl font-bold text-gray-700 footer-text hover:underline"
          onClick={handleSimulationClick}
        >
          Simulation
        </button>
        <Link className="text-xl font-bold text-gray-700 footer-text hover:underline" to="/Contact">
          Contact
        </Link>
      </div>
      <h1 onClick={() => setShowModal(true)} className="text-center mt-4 cursor-pointer hover:text-gray-500">PROTECTION DES DONNEES</h1>
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center bg-gray-200 rounded py-4" id="modal-title">
                      PROTECTION DES DONNÉES
                    </h3>
                    <div className="mt-2">
                      <RGPDContent /> {/* Utilisation du composant RGPDContent */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#1D63FF] text-base font-medium text-white  sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-center mt-4">
        <a href="https://www.wipo.int/copyright/fr/" target="blank" style={{ color: "inherit", textDecoration: "none" }}>
          {"Tout droit reservé © " + new Date().getFullYear() + " FBConseils"}
        </a>
      </div>
    </div>
  );
};

export default Footer;
