export default function InputsVehicleVU({ index, value, setValue }: { index: number, value: Number, setValue: React.Dispatch<React.SetStateAction<number>> }) {
    return (
        <div key={`utilityVehicle${index}`}>
            <label htmlFor={`utilityVehicle${index}`} className="input-label font-bold text-slate-600">
                Indiquez le prix du véhicule utilitaire {index + 1}
            </label>
            <input
                className="input_contact"
                type="text"
                id={`utilityVehicle${index}`}
                placeholder={`Indiquez le prix du VU ${index + 1}`}
                onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                        setValue(Number(e.target.value))
                    }
                }}
                value={value.toString()}
                required
            />
            <br />
            <br />
        </div>
    )
}
