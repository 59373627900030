import { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMediaQuery } from 'react-responsive';


const marks = {
  0: '0€',
  100000: '100k€',
  500000: '+500K€',
};

export const AnnualRevenueSlider = ({ onChange, value }) => {
  const [annualRevenue, setAnnualRevenue] = useState(value);
  const [inputValue, setInputValue] = useState('');

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sliderWidth = isMobile ? 'calc(70% + 20px)' : '90%';
  const sliderMarginLeft = isMobile ? '20px' : '2%';

  useEffect(() => {
    setInputValue(formatInputValue(annualRevenue));
  }, [annualRevenue]);

  const handleSliderChange = (value) => {
    setAnnualRevenue(value);
    onChange(value);
  };

  const handleInputChange = (e) => {
    const inputVal = e.target.value;

    // Retirez tout caractère non numérique pour ne laisser que les chiffres
    const numericVal = parseInt(inputVal.replace(/\D/g, ''), 10);

    if (!isNaN(numericVal)) {
      setAnnualRevenue(numericVal);
      onChange(numericVal);
      setInputValue(formatInputValue(numericVal));
    } else {
      setAnnualRevenue(0);
      onChange(0);
      setInputValue('');
    }
  };

  const formatInputValue = (value) => {
    // Assurez-vous de retourner une chaîne vide si la valeur est 0 pour afficher le placeholder
    return value === 0 ? '' : `${value.toLocaleString('fr-FR')}€`;
  };

  return (
    <div>
      <Slider
        value={annualRevenue}
        onChange={handleSliderChange}
        step={1000}
        marks={marks}
        min={0}
        max={500000}
        style={{ width: sliderWidth, marginLeft: sliderMarginLeft }}
      />
      <br />
      <input
        type="text" // Changer à "text" pour le formatage
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Entrez un montant"
       
        style={{
          width: '100%',
          textAlign: 'center',
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
};
