import React, { RefObject, useState, useEffect, FC } from 'react';
import { motion } from 'framer-motion';
import { useNavigate} from 'react-router-dom';
import ShimmerButton from './magicui/shimmer-button';

interface StepType {
  number: number;
  title: string;
  description: string;
  emoji: string;
}

const steps: StepType[] = [
  {
    number: 1,
    title: 'Comprendre votre projet',
    description: 'Répondez à quelques questions simples sur notre simulateur pour nous aider à mieux comprendre votre projet et vos objectifs. Nous vous demandons des informations telles que le chiffre d’affaires, les investissements, les apports, votre salaire souhaité, etc.',
    emoji: '📝',
  },
  {
    number: 2,
    title: 'Analyse des besoins',
    description: 'Grâce à notre solution et notre expertise en création d’entreprise, nous étudions vos réponses et réalisons un budget prévisionnel avec un tableau de trésorerie pour chacune des formes juridiques possibles. Nous comparons les coûts en termes de charges sociales, impôts professionnels et impôts personnels générés. Nous identifions la viabilité du projet en validant la croissance de la trésorerie notamment.',
    emoji: '🔍',
  },
  {
    number: 3,
    title: 'Recommandation personnalisée',
    description: 'Une fois la simulation faite et le résultat déterminé, nous vous indiquons immédiatement la forme juridique la mieux adaptée à votre situation.',
    emoji: '💡',
  },
  {
    number: 4,
    title: 'Planification financière',
    description: 'Pour obtenir un budget prévisionnel pour la forme juridique recommandée et/ou planifier votre avenir financier avec plus de confiance, deux possibilités:',
    emoji: '📈',
  },
  {
    number: 5,
    title: 'Rédaction des statuts',
    description: 'Afin de vous accompagner jusqu’au bout dans le lancement de votre projet, vous pouvez également à l’issue de votre budget prévisionnel obtenir les statuts de votre société.',
    emoji: '📝',
  },
];

const handlePayment1 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, navigate: ReturnType<typeof useNavigate>) => {
  event.preventDefault();
  navigate('/Form');
};

const handlePayment2 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  event.preventDefault();
  window.open('https://calendly.com/fbsimulateurs/rendez-vous-conseils-budget-previsionnel', '_blank');
};

const useOnScreen = (ref: RefObject<Element>): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: '0px' }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return isIntersecting;
};

interface StepProps {
  step: StepType;
  index: number;
}

const Step: FC<StepProps> = ({ step, index }) => {
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref);

  return (
    <motion.div
      ref={ref}
      className="relative flex items-center mb-16"
      initial={{ opacity: 0, y: 50 }}
      animate={onScreen ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 1.0 }}
    >
      <div className="roadmap-circle-container">
        <motion.div
          className="bg-[#1D63FF] text-white font-bold rounded-full roadmap-circle w-16 h-16 flex items-center justify-center border-4 border-[#ffeb3b]"
        >
          {step.number}
        </motion.div>
      </div>

      <div className="flex flex-col ml-8 mt-6">
        <h3 className="text-lg font-bold mb-4 text-[#1D63FF]">
          <span className="mr-2">{step.emoji}</span>
          {step.title}
        </h3>
        <p className="text-gray-500">
          {step.description}
          {step.number === 4 && (
            <>
            <br/>
              {'Vous souhaitez récupérer directement votre budget prévisionnel issu de votre simulation réalisée au tarif exceptionnel de 50€ HT.'}
              <div className="flex justify-center mt-2">
                <ShimmerButton
                  className="bg-blue-500 text-white font-bold text-sm py-2 px-3"
                  onClick={(event) => handlePayment1(event, navigate)}
                >
                  Démarrer ma simulation
                </ShimmerButton>
              </div>
              <br />
              <strong>OU</strong>
              <br />
              <br/>
              {'Vous souhaitez prendre un rendez-vous avec nous pour en discuter, personnaliser de manière encore plus précise votre budget prévisionnel au tarif de 100€ HT.'}
              <div className="flex justify-center mt-2">
                <ShimmerButton
                  className="bg-blue-500 text-white font-bold text-sm py-2 px-3"
                  onClick={handlePayment2}
                >
                  Prendre RDV
                </ShimmerButton>
              </div>
            </>
          )}
        </p>
      </div>
      {index < steps.length - 1 && (
        <div
          className="absolute left-8 w-1.5 bg-gray-300"
          style={{
            top: '100%',
            height: 'calc(100% - 5%)',
          }}
        />
      )}
    </motion.div>
  );
};

const Roadmap: FC = () => {
  return (
    <div id="fonctionnement" className="max-w-6xl mx-auto p-8 md:p-16">
      <h2 className="mb-8 text-4xl font-bold text-black mt-16 text-center">Comment ça marche ?</h2>
      <div className="relative">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Step step={step} index={index} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
