import React from 'react';
import Card from './cards';


const CardsContainer: React.FC = () => {
 
  const cards = [
    { title: 'Micro', description: 'Structure libre, pas de personnalité morale,\nLes charges réelles ne sont pas déductibles,\nImposition et charges sociales calculées sur les recettes encaissées après un abattement forfaitaire en fonction de vos types de revenus BIC, BNC, PL' },
    { title: 'EI', description: 'Structure libre, pas de personnalité morale,\nCharges déductibles au réel.\nImposition et charges sociales calculées sur le bénéfice réel.' },
    { title: 'SARL', description: 'Société, dotée d’une personne morale\nBénéfice imposé à l\'impôt sur les sociétés,\nCotisations sociales du dirigeant déductibles,\nRémunération des dirigeants imposée à l\'impôt sur le revenu et soumise aux charges sociales des indépendants.\nOptimisation de la rémunération' },
    { title: 'SAS', description: 'Société, dotée d’une personne morale\nBénéfice imposé à l\'impôt sur les sociétés,\nStatut d\'assimilé salarié pour le dirigeant,\nSalaire net soumis à l\'impôt sur le revenu,\nCotisations sociales du régime salarié,\nVersement de dividendes soumis à la flat tax' },
  ];

  return (
    <div className="py-8 px-4 md:px-8 mt-40">
      <h2 id="juridique" className="text-4xl md:text-5xl font-bold text-center md:text-left md:ml-28 mb-16 text-[#273c75] flex justify-center">
        Les formes juridiques
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mx-auto px-4 sm:px-8 lg:px-20 ml-20">
        {cards.map((card, index) => (
          <Card key={index} title={card.title} description={card.description} delay={index * 0.2} />
        ))}
      </div>
    </div>
  );
};

export default CardsContainer;
