
import './styles/index.css';
import Nav from './components/NavBar';
import Homes from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Forms from './pages/Simulation';
import Contact from './pages/Contact';
import CardsContainer from './components/Card/CardsContainer';
import DropDownJuridique from './components/Card/DropDownJuridique';
import Info from './pages/Info';
import { Previsionnel } from './pages/Previsionnel';
import Status from './pages/Status';
import Success from './components/Success';
import Fonction from './components/RoadMap';
import Footer from './components/Footer';

import { Helmet } from "react-helmet";


const App = () => {







  return (

    <>
      <Helmet>
        <title>FBConseils</title>
        <meta name="description" content="Que vous soyez un artisan, un commerçant, un libéral ou un consultant, notre simulateur vous aide à choisir la forme juridique idéale pour créer votre entreprise ou société. Profitez d'une simulation précise pour orienter votre décision et planifier votre budget prévisionnel." />
      </Helmet>

      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Homes />} />
          <Route path="/form" element={<Forms />} />
          <Route path="/status" element={<Status />} />
          <Route path="/success" element={<Success />} />
          <Route path="/fonctionnement" element={<Fonction />} />
          <Route path="/cards" element={<CardsContainer />} />
          <Route path="/Previsionnel" element={<Previsionnel />} />
          <Route path="/DropDownJuridique" element={<DropDownJuridique />} />
          <Route path="/info" element={<Info />} />
          <Route path="/contact" element={<Contact />} />







        </Routes>
        <div className="footer-wrapper mt-28">
          <Footer />
        </div>
      </Router>

    </>
  );
};

export default App;
