import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calculator, Scales, ChartBar, House, QuestionMark, PaperPlaneTilt } from "@phosphor-icons/react";

const Nav: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    // Force le défilement en haut à chaque changement de route
    window.scrollTo(0, 0);
  }, [location]);
  
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const [isOpen, setIsOpen] = useState(false);
  const [elementFocused, setElementFocused] = useState<number | null>(null);

  const navigate = useNavigate();

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 1280);
  }, []);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setIsScrolled(currentScrollY > 50);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, handleResize]);

  const navigateToSection = (sectionId: string) => {
    // Forcer le défilement en haut de la page immédiatement
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    
    if (window.location.pathname === '/') {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      navigate(`/#${sectionId}`);
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); // Assurez-vous d'être en haut après la navigation
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
    setIsOpen(false);
  };
  
  

  const handleMouseMove = (index: number) => {
    setElementFocused(index);
  };

  const handleMouseLeave = () => {
    setElementFocused(null);
  };

  return (
    <>
      <main className={`${isMobile ? 'content-margin-mobile' : ''}`} id="home">
        <nav
          id='navbar'
          className={`fixed top-0 left-0 right-0 w-full h-16 z-10 transition-all duration-300 py-12 ${isScrolled ? 'bg-yellow-gradient shadow-lg' : 'bg-transparent'} ${isMobile ? 'mobile-nav' : ''}`}
          style={isScrolled ? { background: 'linear-gradient(15deg, #ffffff 0%, #ffeb3b 70%, #ffeb3b 100%)' } : {}}
        >
          <div className="container mx-auto px-4 flex justify-between items-center text-[#1D63FF] h-full">
            <div className="flex items-center flex-shrink-0">
            <img
  src='/img/B__1_-removebg-preview.png'
  alt="logo"
  className='hover:scale-110 transform transition-all duration-500 ease-in-out w-auto h-[7rem] sm:h-[7rem] md:h-[10rem] mt-2 cursor-pointer'
  onClick={() => navigateToSection('home')}
/>

            </div>
            <div className={`rounded-hover-container flex-grow flex items-center justify-end space-x-4 ${isMobile ? 'hidden' : 'flex'}`} onMouseLeave={handleMouseLeave}>
              {[
                { path: "/", icon: <House size={25} />, label: "Accueil" },
                { path: "/DropDownJuridique", label: "FORME JURIDIQUE" },
                { path: "/Previsionnel", label: "BUDGET PREVISIONNEL" },
                { path: "/status", label: "STATUTS" },
                { path: "/fonctionnement", label: "FONCTIONNEMENT" },
                { path: "/contact", label: "CONTACT" }
              ].map((item, index) => (
                <Link
                  to={item.path}
                  key={item.label}
                  className={`rounded-hover px-3 py-2 text-base font-medium relative z-10 ${
                    elementFocused === index ? 'text-white' : 'text-[#1D63FF]'
                  }`}
                  onMouseEnter={() => handleMouseMove(index)}
                >
                  {item.icon ? item.icon : item.label}
                  <AnimatePresence>
                    {elementFocused === index && (
                      <motion.div
                        className="absolute inset-0 bg-[#1D63FF] rounded-md -z-10"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{
                          duration: 0.3,
                          ease: [0.25, 0.1, 0.25, 1], // Courbe d'accélération pour une transition fluide
                        }}
                        layoutId="hover-bg"
                        layout
                      />
                    )}
                  </AnimatePresence>
                </Link>
              ))}
            </div>
            <div className={`${isMobile ? "flex justify-end w-full h-full" : "hidden"} items-center`}>
              <button onClick={() => setIsOpen(!isOpen)} className="text-3xl">
                {isOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>
          </div>
          <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            variants={{
              open: { opacity: 1, x: 0, transition: { duration: 0.5 } },
              closed: { opacity: 0, x: '-100%', transition: { duration: 0.5 } }
            }}
            className={`fixed top-0 left-0 w-3/4 h-full bg-white z-10 flex flex-col items-start justify-center pl-8 space-y-4 shadow-lg ${isMobile ? "mobile-menu-open" : ""}`}
          >
            <Link to="/" onClick={() => navigateToSection('home')} className="text-xl flex items-center space-x-2 text-[#1D63FF]">
            <House size={36} />  <span>Accueil</span>
            </Link>
            <Link to="/DropDownJuridique" onClick={() => navigateToSection('juridique')} className="text-xl flex items-center space-x-2 text-[#1D63FF]">
            <Scales size={36} /> <span>FORME JURIDIQUE</span>
            </Link>
            <Link to="/Previsionnel" onClick={() => navigateToSection('budget')} className="text-xl flex items-center space-x-2 text-[#1D63FF]">
            <Calculator size={36} /> <span>BUDGET PREVISIONNEL</span>
            </Link>
            <Link to="/status" onClick={() => navigateToSection('statuts')} className="text-xl flex items-center space-x-2 text-[#1D63FF]">
            <ChartBar size={36} /> <span>STATUS</span>
            </Link>
            <Link to="/fonctionnement" onClick={() => navigateToSection('fonctionnement')} className="text-xl flex items-center space-x-2 text-[#1D63FF]">
            <QuestionMark size={36} /> <span>FONCTIONNEMENT</span>
            </Link>
            
            <Link 
  to="/contact" 
  className="text-xl flex items-center space-x-2 text-[#1D63FF]" 
  onClick={() => setIsOpen(false)}
>
  <PaperPlaneTilt size={36} /> <span>CONTACT</span>
</Link>

          </motion.div>
        </nav>
      </main>
    </>
  );
};

export default Nav;
