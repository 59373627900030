import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function DropDownJuridique() {
  const [isOpen, setIsOpen] = useState({
    microEntreprise: false,
    entrepriseIndividuelle: false,
    eurlSarl: false,
    sasuSas: false,
  });

  const toggleOpen = (key: 'microEntreprise' | 'entrepriseIndividuelle' | 'eurlSarl' | 'sasuSas') => {
    setIsOpen((prevState) => {
      // Fermer tous les autres menus
      const newState = {
        microEntreprise: false,
        entrepriseIndividuelle: false,
        eurlSarl: false,
        sasuSas: false,
      };
      // Basculer l'état du menu cliqué
      newState[key] = !prevState[key];
      return newState;
    });
  };

  const ChevronDown = () => (
    <svg width={24} height={24} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m19 9-7 7-7-7" />
    </svg>
  );

  const ChevronUp = () => (
    <svg width={24} height={24} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M17.648 15.248a1.2 1.2 0 0 1-1.696 0L12 11.297l-3.952 3.951a1.2 1.2 0 0 1-1.696-1.696l4.8-4.8a1.2 1.2 0 0 1 1.696 0l4.8 4.8a1.2 1.2 0 0 1 0 1.696Z" clipRule="evenodd" />
    </svg>
  );

  return (
<div className="min-h-screen flex flex-col items-center justify-start p-4">
  <div className="w-full max-w-6xl mb-8 relative">
    <div className="flex flex-col lg:flex-row items-start">
      <div className="flex-1">
        <h1 className="text-3xl sm:text-2xl md:text-3xl lg:text-3xl font-bold mb-6 mt-28 text-black text-left">
          Vous souhaitez devenir consultant en informatique, restaurateur, infirmier ou toutes activités de conseils, commerciales, artisanales ou libérales …
        </h1>
        <div className='text-gray-600'>
          <p className="text-lg mb-4">
            Savez-vous que plusieurs formes juridiques s'offrent à vous?
            <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png" alt="Thinking Face" width="30" height="30" className="inline-block align-middle ml-2" />
          </p>
          <p className="text-lg mb-4">Nous sommes là pour vous aider à y voir plus clair.</p>
          <p className="text-lg mb-4 flex items-center">
            Les quatre formes juridiques les plus répandues sont:
          </p>
          <ul className="list-disc list-inside mb-6">
            🔵 La micro entreprise ou MICRO<br />
            🔵 L’entreprise individuelle ou E.I<br />
            🔵 La Société à Responsabilité Limitée ou EURL / SARL<br />
            🔵 La Société par Actions Simplifiée ou SASU / SAS
          </ul>
        </div>
      </div>
      <div className="flex-none ml-4 lg:mt-40">
        <img src="/assets/Home.png" alt="Consultant Information" className="max-w-xs" />
      </div>
    </div>
  </div>

      <div className="w-full max-w-6xl flex flex-wrap items-start">
        <div className="w-full md:w-1/2 pr-2">
          {/* Micro entreprise */}
          <div className="bg-white rounded-box shadow-lg p-12 mb-4 cursor-pointer w-full" onClick={() => toggleOpen('microEntreprise')}>
            <div className="flex justify-center items-center">
              <label className={`text-xl font-medium text-[#1D63FF] ${!isOpen.microEntreprise ? 'truncate' : ''} text-center flex-grow font-semibold`}>MICRO</label>
              <span className="flex-shrink-0 ml-2">{isOpen.microEntreprise ? <ChevronUp /> : <ChevronDown />}</span>
            </div>
            <AnimatePresence>
              {isOpen.microEntreprise && (
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                  <div className="mt-4">
                    <p>
                      👉 La micro entreprise est une <strong>entreprise individuelle</strong> au régime fiscal <strong>MICRO</strong>. <br /><br />
                      👉Cette structure ne présente <strong>pas de coût de création</strong> de société mais seulement quelques frais d’enregistrement pour déclarer le début d’activité. <br /><br />
                      👉Il n’y a pas de différenciation entre l’entrepreneur personne physique et l’entreprise. <br /><br />
                      👉Le montant des charges sociales est calculé directement avec un <strong>taux forfaitaire</strong> (qui varie en fonction de la nature de l’activité) sur le chiffre d’affaires réalisé (CA x taux de cotisations = cotisations sociales dues). <br /><br />
                      👉Le chef d'entreprise est affilié à la <strong>sécurité sociale des indépendants</strong>. <br /><br />
                      👉Pour l’impôt sur les revenus, il y a deux possibilités.<br/> Soit l’option pour le <strong>prélèvement libératoire</strong>, un taux forfaitaire (qui dépend de l’activité réalisée) s’applique au CA. Le paiement est effectué à l’URSSAF en même temps que vos charges sociales.<br/> Soit l’option pour le <strong>barème progressif</strong>. Vos revenus de la micro entreprise viennent s’ajouter à vos autres revenus du foyer fiscal après abattement (qui varie selon l’activité) sur le CA réalisé. <br /><br />
                      👉La présence d’une comptabilité <strong>n’est pas une obligation</strong>. Cela est toutefois conseillé si vous êtes soumis à TVA (si dépassement des seuils de CA). <br /><br />
                      👉Cette forme est idéale pour se lancer ou pour toute activité limitée ayant très peu de charges, notamment les consultants. Idéale également quand vous avez une activité salarié à côté ou que vous ne générez pas beaucoup de CA. <br /><br />
                      ⚠️Il faut faire attention aux <strong>seuils de chiffre d’affaires</strong> qui sont à respecter pour pouvoir conserver ce statut. <br /><br />
                      👍<strong>Avantages</strong><br />
                      🔵Grande simplicité administrative<br />
                      🔵Faible coût de création et de tenue comptable<br />
                      🔵Idéale si complément double activité<br /><br />
                      👎<strong>Inconvénients</strong><br />
                      🔵Options vite limitées en termes de gestion<br />
                      🔵Pas de déductibilité de charge
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Entreprise individuelle */}
          <div className="bg-white rounded-box shadow-lg p-12 mb-4 cursor-pointer w-full" onClick={() => toggleOpen('entrepriseIndividuelle')}>
            <div className="flex justify-center items-center">
              <label className={`text-xl font-medium text-[#1D63FF] ${!isOpen.entrepriseIndividuelle ? 'truncate' : ''} text-center flex-grow font-semibold`}>E.I</label>
              <span className="flex-shrink-0 ml-2">{isOpen.entrepriseIndividuelle ? <ChevronUp /> : <ChevronDown />}</span>
            </div>
            <AnimatePresence>
              {isOpen.entrepriseIndividuelle && (
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                  <div className="mt-4">
                    <p>
                      👉 Il s’agit d’une <strong>entreprise individuelle</strong> mais au régime <strong>réel d’imposition</strong>. <br /><br />
                      👉 Cette structure ne présente <strong>pas de coût de création</strong> de société mais seulement quelques frais d’enregistrement pour déclarer le début d’activité. <br /><br />
                      👉Il n’y a pas de différenciation entre l’entrepreneur personne physique et l’entreprise. <br /><br />
                      👉Les cotisations sociales et l’impôt sur les revenus sont, contrairement à la micro entreprise, <strong>calculés sur le bénéfice réel</strong> réalisé, basé sur le chiffre d’affaires auquel on déduit toutes les charges liées à l’activité. <br /><br />
                      👉Le chef d'entreprise est affilié à la <strong>sécurité sociale des indépendants</strong>. <br /><br />
                      👉Il est <strong>obligatoire de tenir une comptabilité</strong> avec un dépôt de liasse fiscale annuelle aux impôts. <br /><br />
                      👍<strong>Avantages</strong><br />
                      🔵Faible coût de création<br />
                      🔵Toutes les charges sont déductibles<br /><br />
                      👎<strong>Inconvénients</strong><br />
                      🔵Vous n’avez pas de contrôle sur l’imposition<br />
                      🔵Les charges sociales peuvent donc facilement être élevées<br />
                      🔵L’impôt sur le revenu peut facilement être élevé
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        <div className="w-full md:w-1/2 pl-2">
          {/* EURL / SARL */}
          <div className="bg-white rounded-box shadow-lg p-12 mb-4 cursor-pointer w-full" onClick={() => toggleOpen('eurlSarl')}>
            <div className="flex justify-center items-center">
              <label className={`text-xl font-medium text-[#1D63FF] ${!isOpen.eurlSarl ? 'truncate' : ''} text-center flex-grow font-semibold`}>EURL/SARL</label>
              <span className="flex-shrink-0 ml-2">{isOpen.eurlSarl ? <ChevronUp /> : <ChevronDown />}</span>
            </div>
            <AnimatePresence>
              {isOpen.eurlSarl && (
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                  <div className="mt-4">
                    <p>
                      👉La forme juridique de l’EURL ou SARL à l’impôt société est dotée d’une <strong>personnalité morale</strong>, donc la structure est différenciée de la personne physique de l’entrepreneur.
                      Ce qui assure une sécurité en termes de responsabilité pour le chef d’entreprise. <br /><br />
                      👉Le bénéfice est calculé selon le chiffre d’affaires et les charges réelles. Ce résultat est soumis à l'<strong>impôt sur les sociétés</strong>, payé par la société elle-même.  <br /><br />
                      👉La rémunération prise par le dirigeant est <strong>déductible</strong> du résultat et soumise à charges sociales, elles-même déductibles. <br /><br />
                      👉La rémunération prise est ensuite imposée à l’impôt sur les revenus personnels. <br /><br />
                      👉Le dirigeant est affilié à la <strong>sécurité social des indépendants</strong> (si majoritaire au capital de la société). <br /><br />
                      👍<strong>Avantages</strong><br />
                      🔵Possibilité d’optimiser la rémunération<br />
                      🔵Faible taux de cotisations sociales (environ 40% de la rémunération nette prise)<br />
                      🔵Toutes les charges sont déductibles<br /><br />
                      👎<strong>Inconvénients</strong><br />
                      🔵Coûts de création, de tenue de comptabilité obligatoire<br />
                      🔵Couverture sociale limitée, prévoir des complémentaires santé, prévoyance…
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* SASU / SAS */}
          <div className="bg-white rounded-box shadow-lg p-12 mb-4 cursor-pointer w-full" onClick={() => toggleOpen('sasuSas')}>
            <div className="flex justify-center items-center">
              <label className={`text-xl font-medium text-[#1D63FF] ${!isOpen.sasuSas ? 'truncate' : ''} text-center flex-grow font-semibold`}>SASU/SAS</label>
              <span className="flex-shrink-0 ml-2">{isOpen.sasuSas ? <ChevronUp /> : <ChevronDown />}</span>
            </div>
            <AnimatePresence>
              {isOpen.sasuSas && (
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                  <div className="mt-4">
                    <p>
                      👉Le statut juridique de la SASU ou SAS, il s’agit d’une société dotée d’une <strong>personnalité morale</strong> et donc différenciée de la personne physique du dirigeant.
                      Ce qui assure une sécurité en termes de responsabilité pour le chef d’entreprise. <br /><br />
                      👉Le bénéfice est calculé selon le chiffre d’affaires et les charges réelles. Ce résultat est soumis à l'<strong>impôt sur les sociétés</strong> payé par la société elle-même. <br /><br />
                      👉Les salaires pris par les dirigeants via des bulletins de paies sont soumis à <strong>charges sociales</strong> et à <strong>impôt sur les revenus</strong>. <br /><br />
                      👉Les dirigeants sont <strong>assimilés salariés</strong> et cotisent donc au <strong>régime général de la sécurité sociale</strong>. <br /><br />
                      👉Le coût de la rémunération est plus élevé qu'en EURL/SARL mais il est possible de percevoir des dividendes soumis au prélèvement forfaitaire unique (flat tax de 30%) ou au barème progressif sur option. <br /><br />
                      👍<strong>Avantages</strong><br />
                      🔵Toutes les charges sont déductibles<br />
                      🔵Bonne couverture sociale en cas d’arrêt ou invalidité si salaires cotisés suffisants<br />
                      🔵Possibilité d'arbitrer entre salaires et dividendes<br />
                      🔵Si exercice d’une activité en parallèle ou si retraité<br />
                      🔵Si bénéficiaire des ARE (France Travail)<br /><br />
                      👎<strong>Inconvénients</strong><br />
                      🔵Coûts de création, de tenue de la comptabilité obligatoire<br />
                      🔵Fort taux de cotisations sociales (environ 85% du salaire net)
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}
