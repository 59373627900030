import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

const Card: React.FC<{ title: string; description: string; delay: number }> = ({ title, description, delay }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    const currentRef = cardRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [controls]);

  const descriptionLines = description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line} <br />
    </React.Fragment>
  ));

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay } },
      }}
      initial="hidden"
      animate={controls}
      ref={cardRef}
      className="motion-wrapper"
    >
      <div className="w-[250px] h-[320px] bg-[#DFE1EB] rounded-[50px] shadow-[rgba(0,0,0,0.17)_0px_-23px_25px_0px_inset,rgba(0,0,0,0.15)_0px_-36px_30px_0px_inset,rgba(0,0,0,0.1)_0px_-79px_40px_0px_inset,rgba(0,0,0,0.06)_0px_2px_1px,rgba(0,0,0,0.09)_0px_4px_2px,rgba(0,0,0,0.09)_0px_8px_4px,rgba(0,0,0,0.09)_0px_16px_8px,rgba(0,0,0,0.09)_0px_32px_16px] flex flex-col justify-start p-2"
      >
        <h3 className="text-xl font-bold text-center text-[#273c75]">{title}</h3>
        <div className="text-sm text-gray-600 overflow-auto mt-4">
          {descriptionLines}
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
