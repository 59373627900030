// Importer useState et useEffect de React
import { useState, useEffect } from 'react';
import { FaArrowAltCircleUp } from 'react-icons/fa';


export const Arrow = () => {

  // State pour savoir si on est en haut ou pas
  const [isTop, setIsTop] = useState(true);


  useEffect(() => {
    // Détecter le scroll 
    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    }

    // Ajouter l'événement
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div>
      {!isTop && (
        <div 
          className="scrollTop"
          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
        >
            <FaArrowAltCircleUp />
        </div>  
      )}
    </div>
    
  )

}