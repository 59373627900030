export const CookieBanner: React.FC<{ onAccept: () => void, onReject: () => void }> = ({ onAccept, onReject }) => {
    return (
        <div className="fixed inset-0 flex items-end justify-end p-4 md:p-6 bg-grey  z-50">
            <div className="w-full max-w-2xl px-4 md:px-8 py-4 md:py-6 white border border-black rounded-xl bg-white flex flex-col md:flex-row justify-between items-center">
                <p className="text-xs md:text-sm mb-2 md:mb-0 text-gray-600">Nous utilisons des cookies pour améliorer votre expérience. En continuant à utiliser ce site, vous acceptez notre utilisation des cookies.</p>
                <div className="flex">
                    <button className="px-4 md:px-6 py-1 md:py-2 mx-1 bg-[#ffeb3bad] text-gray-600 rounded-full" onClick={onAccept}>Accepter</button>
                    <button className="px-4 md:px-6 py-1 md:py-2 mx-1 bg-white border border-black text-gray-600 rounded-full hover:" onClick={onReject}>Refuser</button>
                </div>
            </div>
        </div>
    );
}
