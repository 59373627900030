import React, { useState, useEffect } from 'react';
import { AnnualRevenueSlider } from '../components/annualRevenueSlider';
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ShimmerButton from '../components/magicui/shimmer-button';
import '../styles/loader.css'
import { Helmet } from 'react-helmet';
import { BusinessData, SelectedRevenue } from '../Types/types';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { DataType } from '../components/DataTable';
import Button from '@mui/material/Button';
import InputsVehicleVP from '../components/InputsVehicleVP';
import InputsVehicleVU from '../components/InputsVehicleVU';
import { Arrow } from '../components/arrow';
//import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Select, { MultiValue } from 'react-select'


ReactGA.initialize('G-78TF44GTX6');
ReactGA.send({ hitType: 'pageview', page: 'window.location.pathname' });


const Forms = () => {



    <Helmet>
        <title>Simulateur de forme juridique - Trouvez la meilleure structure pour votre entreprise | FBConseils</title>
        <meta name="description" content="Utilisez notre simulateur de forme juridique pour déterminer la meilleure structure pour votre entreprise. Répondez à quelques questions simples et nous vous fournirons une recommandation basée sur vos réponses." />
    </Helmet>
    const [loading, setLoading] = useState(false);
    const [investissement, setInvestissement] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [, setNumEmployees] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [, setNumVehicles] = useState(0);
    const [, setNumUtilityVehicles] = useState(0);
    const [showInvestissementMaterielsInput, setShowInvestissementMaterielsInput] = useState(false);
    const [showPersonalVehicleFields, setShowPersonalVehicleFields] = useState(false);
    const [showLoanFields, setShowLoanFields] = useState(false);
    //const [showOtherIncomeField, setShowOtherIncomeField] = useState(false);
    const [tempTaux, setTempTaux] = useState("");



    //multi select 

    const customStyles = {
        control: (baseStyles: any, state: { isFocused: any; }) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            borderColor: '#273c75',
            border: '2px solid',
            padding: '12px'
        }),
        multiValue: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
        }),
        multiValueLabel: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: '#f4f4f4 ',
            fontWeight: '550',
            border: '1px solid #273c75',
            borderRadius: '8px',
            padding: '8px'
        }),
        multiValueRemove: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            ':hover': {
                backgroundColor: 'transparent',
                color: '#273c75',
            },
        }),
        menuList: (baseStyles: any) => ({
            ...baseStyles,
            maxHeight: '200px', // Augmentez cette valeur pour permettre plus de défilement
            overflowY: 'auto',
        }),
    };



    const removeAccents = (str: string) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleRevenueSelect = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
        const updatedRevenues = selectedOptions.map(option => {
            const normalizedLabel = removeAccents(option.label);
            const existingRevenue = selectedRevenues.find(revenue => removeAccents(revenue.type) === normalizedLabel);
            return existingRevenue || { type: option.label, amount: '' };
        });
        setSelectedRevenues(updatedRevenues);
    };

    const handleRevenueAmountChange = (index: number, amount: string) => {
        const updatedRevenues = [...selectedRevenues];
        updatedRevenues[index].amount = amount;
        setSelectedRevenues(updatedRevenues);
    };

    const colourOptions = [
        { value: 'revenusFonciers', label: 'Revenus fonciers pas cumulable avec micro foncier' },
        { value: 'revenusMicroFoncier', label: 'Revenus micro foncier max 15 000€' },
        { value: 'revenusCapitauxMobilierFlatTax', label: 'Revenus capitaux mobilier flat tax' },
        { value: 'revenusCapitauxMobilierBaremeProgressif', label: 'Revenus capitaux mobilier option barème progressif' },
        { value: 'revenuBIC', label: 'Revenu BIC' },
        { value: 'revenuMicroBICVentes', label: 'Revenu micro BIC ventes de marchandises' },
        { value: 'revenuMicroBICServices', label: 'Revenu micro BIC prestations de services' },
        { value: 'revenuBNC', label: 'Revenu BNC' },
        { value: 'revenuMicroBNC', label: 'Revenu micro BNC' }
    ]



    //const navigate = useNavigate();
    const { scrollYProgress } = useViewportScroll();
    const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [userName, setUserName] = useState<string>('');
    const [userFirstName, setUserFirstName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [userCompany,] = useState<string>('');
    const [userPriceOfLocationPro,] = useState<number>(0);
    const [userBenefice, setUserBenefice] = useState<string>('');
    const [userActivity, setUserActivity] = useState<string>('');
    const [userAnnualTurnover, setUserAnnualTurnover] = useState<number>(0);
    const [userPriceOfAnnualCommercialLocal, setUserPriceOfAnnualCommercialLocal] = useState<number>(0);
    const [userAmountOfTheStartingStock, setUserAmountOfTheStartingStock] = useState<number>(0);
    const [userContainEmployee, setUserContainEmployee] = useState<string>('');
    const [userNumberOfEmployee, setUserNumberOfEmployee] = useState<number>(0);
    const [userGrossMonthlySalary1, setUserGrossMonthlySalary1] = useState<number>(0);
    const [userGrossMonthlySalary2, setUserGrossMonthlySalary2] = useState<number>(0);
    const [userGrossMonthlySalary3, setUserGrossMonthlySalary3] = useState<number>(0);
    const [userNetMonthlyRemuneration, setUserNetMonthlyRemuneration] = useState<number>(0);
    const [userMaterialInvestment, setUserMaterialInvestment] = useState<string>('');
    const [userNumberMaterialInvestment, setUserNumberMaterialInvestment] = useState<number>(0);
    const [userVehicleInvestment, setUserVehicleInvestment] = useState<string>('');
    const [userNumberVehicleVP, setUserNumberVehicleVP] = useState<number>(0);
    const [userNumberVehicleVU, setUserNumberVehicleVU] = useState<number>(0);
    const [userPriceVP1, setUserPriceVP1] = useState<number>(0);
    const [userCO2VP1, setUserCO2VP1] = useState<number>(0);
    const [userDateRegistrationVP1, setUserDateRegistrationVP1] = useState<number>(0);
    const [userPriceVP2, setUserPriceVP2] = useState<number>(0);
    const [userCO2VP2, setUserCO2VP2] = useState<number>(0);
    const [userDateRegistrationVP2, setUserDateRegistrationVP2] = useState<number>(0);
    const [userPriceVP3, setUserPriceVP3] = useState<number>(0);
    const [userCO2VP3, setUserCO2VP3] = useState<number>(0);
    const [userDateRegistrationVP3, setUserDateRegistrationVP3] = useState<number>(0);
    const [userPriceVU1, setUserPriceVU1] = useState<number>(0);
    const [userPriceVU2, setUserPriceVU2] = useState<number>(0);
    const [userPriceVU3, setUserPriceVU3] = useState<number>(0);
    const [userProposedShareCapital, setUserProposedShareCapital] = useState<number>(0);
    const [userPersonalContributionOfMoney, setUserPersonalContributionOfMoney] = useState<number>(0);
    const [userLoan, setUserLoan] = useState<string>('');
    const [userLoanAmount, setUserLoanAmount] = useState<number>(0);
    const [userLoanTaux, setUserLoanTaux] = useState<number>(0);
    const [userLoanNumberOfYear, setUserLoanNumberOfYear] = useState<number>(1);
    const [userUsePersonalVehicle, setUserUsePersonalVehicle] = useState<string>('');
    const [userNumberOfTaxHorses, setUserNumberOfTaxHorses] = useState<number>(0);
    const [userNumberOfAnnualProfessionalKM, setUserNumberOfAnnualProfessionalKM] = useState<number>(0);
    const [userNumberOfFiscalPart, setUserNumberOfFiscalPart] = useState<number | null>(1);
    const [userOtherAnnualSalariedIncome,] = useState<number>(0);
    const [userOtherSalariedIncomeOfYourHousehold, setUserOtherSalariedIncomeOfYourHousehold] = useState<number>(0);
    const [userOtherSalariedAnnual,] = useState<number>(0);
    const [selectedRevenues, setSelectedRevenues] = useState<SelectedRevenue[]>([]);



    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const isMobile = width < 768;
            setIsMobile(isMobile);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [userData, setUserData] = useState<BusinessData | null>(null);
    const [responseData, setResponseData] = useState<DataType | null>(null);


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (userFirstName === "" && userName === "") {
            setErrorMessage("Le prénom et le nom sont obligatoires");
            return
        } else if (userAnnualTurnover === 0) {
            setErrorMessage("Le chiffre d'affaire est obligatoire");
            return
        } else if (userActivity === "") {
            setErrorMessage("L'activité est obligatoire");
            return
        } if (userContainEmployee === "") {
            setErrorMessage("La présence de salarié est obligatoire");
            return
        } else if (userMaterialInvestment === "") {
            setErrorMessage("L'investissement en matériel est obligatoire");
            return
        } else if (userVehicleInvestment === "") {
            setErrorMessage("L'investissement véhicule est obligatoire");
            return
        } else if (userLoan === "") {
            setErrorMessage("L'emprunt est obligatoire");
            return
        } else if (userLoanAmount === 0 && userLoan === "OUI") {
            setErrorMessage("Le montant de l'emprunt est obligatoire");
            return
        } else if (userUsePersonalVehicle === "") {
            setErrorMessage("L'utilisation du véhicule personnel est obligatoire");
            return
        } else {
            setErrorMessage("");
            setLoading(true);
        }

        const finalNumberOfFiscalPart = Math.max(1, userNumberOfFiscalPart || 1);
        const apiUrl = "https://fbconseil-c706cd2b1837.herokuapp.com/api/simulation";
        //const apiUrl = "http://localhost:3000/api/simulation";

        if (!apiUrl) {
            throw new Error('La variable d\'environnement REACT_APP_API_URL n\'est pas définie');
        }
        try {
            const data: BusinessData = {
                // ... les données utilisateur

                name: userName,
                firstName: userFirstName,
                emailAddress: userEmail,
                companyName: userCompany,
                activity: userActivity,
                annualTurnover: userAnnualTurnover,
                priceOfAnnualCommercialLocal: userPriceOfAnnualCommercialLocal,
                amountOfTheStartingStock: userAmountOfTheStartingStock,
                priceOfTheLocationProject: userPriceOfLocationPro,
                containEmployee: userContainEmployee,
                numberOfEmployee: userNumberOfEmployee,
                grossMonthlySalary1: userGrossMonthlySalary1,
                grossMonthlySalary2: userGrossMonthlySalary2,
                grossMonthlySalary3: userGrossMonthlySalary3,
                netMonthlyRemuneration: userNetMonthlyRemuneration,
                materialInvestment: userMaterialInvestment,
                numberMaterialInvestment: userNumberMaterialInvestment,
                vehicleInvestment: userVehicleInvestment,
                numberVehicleVP: userNumberVehicleVP,
                numberVehicleVU: userNumberVehicleVU,
                priceVP1: userPriceVP1,
                cO2VP1: userCO2VP1,
                dateRegistrationVP1: userDateRegistrationVP1,
                priceVP2: userPriceVP2,
                cO2VP2: userCO2VP2,
                dateRegistrationVP2: userDateRegistrationVP2,
                priceVP3: userPriceVP3,
                cO2VP3: userCO2VP3,
                dateRegistrationVP3: userDateRegistrationVP3,
                priceVU1: userPriceVU1,
                priceVU2: userPriceVU2,
                priceVU3: userPriceVU3,
                proposedShareCapital: userProposedShareCapital,
                personalContributionOfMoney: userPersonalContributionOfMoney,
                loan: userLoan,
                loanAmount: userLoanAmount,
                loanTaux: userLoanTaux / 100,
                loanNumberOfYear: userLoanNumberOfYear,
                usePersonalVehicle: userUsePersonalVehicle,
                numberOfTaxHorses: userNumberOfTaxHorses,
                numberOfAnnualProfessionalKM: userNumberOfAnnualProfessionalKM,
                numberOfFiscalPart: finalNumberOfFiscalPart,
                otherAnnualSalariedIncome: userOtherAnnualSalariedIncome,
                otherSalariedIncomeOfYourHousehold: userOtherSalariedIncomeOfYourHousehold,
                otherSalariedAnnual: userOtherSalariedAnnual,
                benefice: userBenefice,
                selectedRevenues: selectedRevenues
            };

            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setResponseData(response.data.data);
            setUserData(data); // Stocker les données utilisateur ici
            setLoading(false);
            handleOpen();
        } catch (error) {
            setErrorMessage("Une erreur est survenue");
        } finally {
            setLoading(false);
        }
    };


    function generateInputsVehicleVP(index: number) {
        switch (index) {
            case 0:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP1} setValue1={setUserPriceVP1} value2={userCO2VP1} setValue2={setUserCO2VP1} value3={userDateRegistrationVP1} setValue3={setUserDateRegistrationVP1} />;
            case 1:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP2} setValue1={setUserPriceVP2} value2={userCO2VP2} setValue2={setUserCO2VP2} value3={userDateRegistrationVP2} setValue3={setUserDateRegistrationVP2} />;
            case 2:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP3} setValue1={setUserPriceVP3} value2={userCO2VP3} setValue2={setUserCO2VP3} value3={userDateRegistrationVP3} setValue3={setUserDateRegistrationVP3} />;
            default:
                return null;
        }
    }


    function generateInputsVehicleVU(index: number) {
        switch (index) {
            case 0:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU1} setValue={setUserPriceVU1} />;
            case 1:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU2} setValue={setUserPriceVU2} />;
            case 2:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU3} setValue={setUserPriceVU3} />;
            default:
                return null;
        }
    }

    const setSalary = (num: number, value: number) => {
        switch (num) {
            case 1:
                setUserGrossMonthlySalary1(value);
                break;
            case 2:
                setUserGrossMonthlySalary2(value);
                break;
            case 3:
                setUserGrossMonthlySalary3(value);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Arrow />
            <div className={`min-h-screen flex items-center justify-center ${isMobile ? 'py-6 px-4' : 'py-28 px-8'} sm:px-6 lg:px-8 pb-20`} style={{ backgroundColor: 'var(--background-color)' }}>
                {isMobile && <motion.div className="progress-bar" style={{ scaleX, originX: 0, scaleY: 2, backgroundColor: "#3f9bf1" }} />}
                <form className="w-full max-w-lg space-y-6 overflow-hidden">

                    <h1 className="text-4xl font-bold mb-10 text-center text-black">Votre Simulation</h1>
                    <div className="input-container"></div>
                    <label htmlFor="name" className="input-label font-bold text-gray-600">Votre nom<span className="asterisk">*</span></label>
                    <input type="text" id="name" className="input_contact" placeholder="Votre nom"
                        onChange={(e) => {
                            if (/^[A-Za-z]*$/.test(e.target.value)) {
                                const value = e.target.value.replace(/[0-9]/g, '').toUpperCase();
                                setUserName(value);
                            }
                        }}
                        value={userName}
                        required
                    />
                    <br />
                    <br />

                    <label htmlFor="surname" className="input-label font-bold text-slate-600">Votre prénom<span className="asterisk">*</span></label>
                    <input type="text" id="surname" className="input_contact" placeholder="Votre prénom"
                        onChange={(e) => {
                            if (/^[A-Za-z]*$/.test(e.target.value)) {
                                const value = e.target.value.replace(/[0-9]/g, '').toUpperCase();
                                setUserFirstName(value);
                            }
                        }}
                        value={userFirstName}
                        required
                    />
                    <br />
                    <br />
                    <hr className="hr" />
                    <br />
                    <label htmlFor="email" className="input-label font-bold text-slate-600">Votre adresse email<span className="asterisk">*</span></label>
                    <input type="email" id="email" className="input_contact" placeholder="Votre adresse email"
                        onChange={(e) => setUserEmail(e.target.value)}
                        value={userEmail}
                        required
                    />
                    <br />
                    <br />
                    <br />

                    <label htmlFor="activity" className="input-label font-bold text-slate-600">Votre Activité<span className="asterisk">*</span></label>
                    <select id="activity" name="activity" className="input_contact" onChange={(e) => setUserActivity(e.target.value)} value={userActivity}>
                        <option value="">Sélectionner</option>
                        <option value="ARTISAN">ARTISAN</option>
                        <option value="COMMERCANT">COMMERCANT</option>
                        <option value="CONSULTANT">CONSULTANT</option>
                        <option value="LIBERAL">LIBERAL</option>
                    </select>
                    {userActivity === "COMMERCANT" ? (
                        <>
                            <label htmlFor="annualCommercialLocal" className="input-label font-bold text-slate-600">Quel est le loyer de votre local commercial annuel?</label>
                            <input className="input_contact" id="annualCommercialLocal" placeholder="montant annuel"
                                onChange={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                        const value = e.target.value ? parseInt(e.target.value, 10) : 0;
                                        setUserPriceOfAnnualCommercialLocal(value)
                                    }
                                }}
                                value={userPriceOfAnnualCommercialLocal}
                                required
                            />
                            <br />
                            <br />
                            <label htmlFor="startingStock" className="input-label font-bold text-slate-600">Indiquez le montant du stock de départ</label>
                            <input className="input_contact" id="startingStock" placeholder="montant annuel"
                                onChange={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                        setUserAmountOfTheStartingStock(Number(e.target.value))
                                    }
                                }}
                                value={userAmountOfTheStartingStock}
                                required
                            />
                            <br />
                            <br />
                        </>
                    ) : null}
                    <br />
                    <p className='font-bold text-slate-600'>Votre Chiffre d'affaires annuel<span className="asterisk">*</span></p>

                    <AnnualRevenueSlider onChange={(value: any) => setUserAnnualTurnover(Number(value))} value={userAnnualTurnover} />
                    <br />
                    <br />
                    <label htmlFor="invertissement" className="input-label font-bold text-slate-600">Présence de salarié<span className="asterisk">*</span></label>
                    <select className="input_contact" id="invertissement" name="investissement" onChange={(e) => {
                        if (e.target.value === 'OUI') {
                            setUserContainEmployee("OUI")
                            setShowInputs(true);
                        } else {
                            setUserContainEmployee("NON")
                            setShowInputs(false);
                        }
                    }} value={userContainEmployee} required>
                        <option value="">Selectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>
                    <br />
                    <br />
                    <br />
                    <br />

                    {showInputs && (
                        <>
                            <label htmlFor="numEmployees" className="input-label font-bold text-slate-600">Indiquez le nombre de salarié</label>
                            <input className="input_contact text-slate-600" id="numEmployees" placeholder="Indiquez le nombre de salarié"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        const numEmployees = Number(e.target.value);
                                        if (numEmployees <= 3) {
                                            setUserNumberOfEmployee(numEmployees);
                                            setNumEmployees(numEmployees);
                                        }
                                    }
                                }}
                                value={userNumberOfEmployee}
                                min="1"
                                max="3"
                                required
                            />
                            <br />
                            <br />
                            {Array.from({ length: userNumberOfEmployee }, (_, index) => index + 1).map((num) => (
                                <div key={`employee${num}`}>
                                    <label htmlFor={`employee${num}`} className="input-label font-bold text-slate-600 ">Salaire brut mensuel Salarié {num}</label>
                                    <input className="input_contact text-slate-600" type="text" id={`employee${num}`} placeholder={`Salaire brut mensuel Salarié ${num}`}
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value)) {
                                                const value = e.target.value ? parseInt(e.target.value, 10) : 0;
                                                setSalary(num, value);
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                            <br />
                            <br />
                        </>
                    )}

                    <label htmlFor="netMonthlyRemuneration" className="input-label font-bold text-slate-600">Votre rémunération net mensuel (ou retrait personnel mensuel) sur la société <span className="asterisk">*</span></label>
                    <input className="input_contact" id="netMonthlyRemuneration" type="text" placeholder="Votre rémunération net mensuel"
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                                setUserNetMonthlyRemuneration(Number(e.target.value))
                            }
                        }}
                        value={userNetMonthlyRemuneration}
                        required
                    />
                    <br />
                    <br />

                    <label htmlFor="benefice" className="input-label font-bold text-slate-600">Vous souhaitez prendre tout le bénéfice <span className="asterisk">*</span></label>
                    <select className="input_contact" id="benefice" name="benefice"
                        onChange={(e) => {
                            const value = e.target.value;
                            setUserBenefice(value);
                        }} value={userBenefice}
                        required>
                        <option value="">Sélectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>


                    <br />
                    <br />

                    <br />
                    <br />
                    <label htmlFor="investment-materials" className="input-label font-bold text-slate-600">Investissement : matériels <span className="asterisk">*</span></label>
                    <select className="input_contact" id="investment-materials" name="investment-materials"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === 'OUI') {
                                setShowInvestissementMaterielsInput(true);
                                setUserMaterialInvestment("OUI")
                            } else {
                                setShowInvestissementMaterielsInput(false);
                                setUserMaterialInvestment("NON")
                            }
                        }} value={userMaterialInvestment}
                        required>
                        <option value="">Selectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>
                    {showInvestissementMaterielsInput && (
                        <>
                            <br />
                            <br />
                            <label htmlFor="materialAmount" className="input-label font-bold text-slate-600">Indiquez le montant (matériels) <span className="asterisk">*</span></label>
                            <input className="input_contact" id="materialAmount" placeholder="Indiquez le montant"
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setUserNumberMaterialInvestment(Number(e.target.value))
                                    }
                                }} value={userNumberMaterialInvestment}
                                required
                            />
                            <br />
                            <br />
                        </>
                    )}
                    <br />
                    <br />

                    <label htmlFor="invertissement-vehicule" className="input-label font-bold text-slate-600">Investissement: véhicule <span className="asterisk">*</span></label>
                    <select className="input_contact" id="invertissement-vehicule" name="investissement" onChange={(e) => {
                        setInvestissement(e.target.value);
                        setUserVehicleInvestment(e.target.value)
                    }} value={userVehicleInvestment}
                        required>
                        <option value="">Selectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>

                    {investissement === 'OUI' && (
                        <>
                            <br />
                            <br />
                            <label htmlFor="numVehicleVP" className="input-label font-bold text-slate-600">Nombre de véhicule de tourisme</label>
                            <input className="input_contact" id="numVehicleVP" placeholder="Indiquez le nombre" required
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setNumVehicles(parseInt(e.target.value));
                                        setUserNumberVehicleVP(Number(e.target.value))
                                    }
                                }} value={userNumberVehicleVP}
                            />
                            <br />
                            <br />

                            {[...Array(userNumberVehicleVP)].map((_, index) => generateInputsVehicleVP(index))}

                            <label htmlFor="numVehicleVU" className="input-label font-bold text-slate-600">Nombre de véhicule utilitaire</label>
                            <input className="input_contact" id="numVehicleVU" placeholder="Indiquez le nombre" required
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setNumUtilityVehicles(parseInt(e.target.value));
                                        setUserNumberVehicleVU(Number(e.target.value));
                                    }
                                }} value={userNumberVehicleVU}
                            />

                            <br />
                            <br />

                            {[...Array(userNumberVehicleVU)].map((_, index) => generateInputsVehicleVU(index))}
                        </>
                    )}

                    <br />
                    <br />
                    <br />
                    <br />
                    <hr />
                    <br />
                    <br />

                    <label htmlFor="proposedShareCapital" className="input-label font-bold text-slate-600">Capital social envisagé <span className="asterisk">*</span></label>
                    <input className="input_contact" id="proposedShareCapital" placeholder="montant"
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                                setUserProposedShareCapital(Number(e.target.value))
                            }
                        }} value={userProposedShareCapital}
                        required
                    />
                    <br />
                    <br />
                    <label htmlFor="personalContribution" className="input-label font-bold text-slate-600">Apport d'argent personnel<span className="asterisk">*</span></label>
                    <input type="text" id="personalContribution" className="input_contact" placeholder="montant"
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                                setUserPersonalContributionOfMoney(Number(e.target.value))
                            }
                        }} value={userPersonalContributionOfMoney}
                        required
                    />
                    <br />
                    <br />

                    <label htmlFor="loan" className="input-label font-bold text-slate-600">Emprunt<span className="asterisk">*</span></label>
                    <select className="input_contact" id="loan" name="loan"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === 'OUI') {
                                setShowLoanFields(true);
                                setUserLoan("OUI")
                            } else {
                                setShowLoanFields(false);
                                setUserLoan("NON")
                            }
                        }} value={userLoan}
                        required>
                        <option value="">Sélectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>

                    {showLoanFields && (
                        <>
                            <br />
                            <br />
                            <label htmlFor="loanAmount" className="input-label font-bold text-slate-600">Indiquez le montant</label>
                            <input className="input_contact" id="loanAmount" onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) setUserLoanAmount(Number(e.target.value))
                            }} value={userLoanAmount} placeholder="Montant" />
                            <br />
                            <br />
                            <label htmlFor="loanRate" className="input-label font-bold text-slate-600">Indiquez le taux %</label>
                            <input type="text" id="loanRate" min="0" className="input_contact"
                                onChange={(e) => {
                                    const value = e.target.value.replace('%', '');
                                    setTempTaux(value);
                                }}
                                onBlur={(e) => {
                                    const value = parseFloat(e.target.value.replace(',', '.'));
                                    const formattedValue = !isNaN(value) ? value.toFixed(2) : '';
                                    setUserLoanTaux(Number(formattedValue));
                                    setTempTaux(formattedValue.replace('.', ','));
                                }} value={tempTaux}
                                placeholder="Taux"
                            />
                            <br />
                            <br />
                            <label htmlFor="loanDuration" className="input-label font-bold text-slate-600">Indiquez la durée en année</label>
                            <input className="input_contact" id="loanDuration" onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) {
                                    setUserLoanNumberOfYear(Number(e.target.value))
                                }
                            }} value={userLoanNumberOfYear} placeholder="Durée en année" />
                            <br />
                            <br />
                        </>
                    )}
                    <br />
                    <br />
                    <br />
                    <hr />
                    <br />
                    <br />
                    <label htmlFor="personalVehicle" className="input-label font-bold text-slate-600">Utilisation du véhicule personnel<span className="asterisk">*</span></label>
                    <select className="input_contact" id="personalVehicle" name="personalVehicle"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === 'OUI') {
                                setUserUsePersonalVehicle("OUI")
                                setShowPersonalVehicleFields(true);
                            } else {
                                setUserUsePersonalVehicle("NON")
                                setShowPersonalVehicleFields(false);
                            }
                        }} value={userUsePersonalVehicle}
                        required>
                        <option value="">Selectionner</option>
                        <option value="OUI">OUI</option>
                        <option value="NON">NON</option>
                    </select>

                    {showPersonalVehicleFields && (
                        <>
                            <br />
                            <br />
                            <label htmlFor="taxHorses" className="input-label font-bold text-slate-600">Indiquez le nombre de chevaux fiscaux</label>
                            <input className="input_contact" id="taxHorses" onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) {
                                    setUserNumberOfTaxHorses(Number(e.target.value))
                                }
                            }} value={userNumberOfTaxHorses} placeholder="Nombre de chevaux fiscaux" />
                            <br />
                            <br />
                            <label htmlFor="professionalKM" className="input-label font-bold text-slate-600">Indiquez le nombre de kilomètres professionnel annuel estimé</label>
                            <input className="input_contact" id="professionalKM" onChange={(e) => {
                                if (/^\d*$/.test(e.target.value)) setUserNumberOfAnnualProfessionalKM(Number(e.target.value))
                            }} value={userNumberOfAnnualProfessionalKM} placeholder="Nombre de kilomètres professionnel annuel estimé" />
                            <br />
                            <br />
                        </>
                    )}
                    <br />
                    <br />
                    <hr />
                    <br />
                    <label htmlFor="fiscalParts" className="input-label font-bold text-slate-600">Indiquez le nombre de part de votre foyer fiscal<span className="asterisk">*</span></label>
<select className="input_contact part-foyer" id="fiscalParts" required
    onChange={(e) => {
        const inputValue = e.target.value;
        if (/^(\d+(\.\d{0,1})?)?$/.test(inputValue)) {
            const value = inputValue !== "" ? parseFloat(inputValue) : null;
            setUserNumberOfFiscalPart(value);
        
            // if (value && value >= 1) {
            //     setShowOtherIncomeField(true);
            // } else {
            //     setShowOtherIncomeField(false);
            // }
        }
    }} value={userNumberOfFiscalPart !== null ? userNumberOfFiscalPart : ''}>
    <option value="1">1</option>
    <option value="1.5">1.5</option>
    <option value="2">2</option>
    <option value="2.5">2.5</option>
    <option value="3">3</option>
    <option value="3.5">3.5</option>
    <option value="4">4</option>
    <option value="4.5">4.5</option>
    <option value="5">5</option>
</select>
<br />
<br />

{/* Le champ est toujours affiché */}
<label htmlFor="householdIncome" className="input-label font-bold text-slate-600">Indiquez les autres salaires, pensions retraite ou indemnités chômage, du foyer annuels.</label>
<input className="input_contact" id="householdIncome" onChange={(e) => {
    if (/^\d*$/.test(e.target.value)) setUserOtherSalariedIncomeOfYourHousehold(Number(e.target.value))
}} value={userOtherSalariedIncomeOfYourHousehold} placeholder="Autres revenus salariés" />
<br />
<br />



                    {/*label htmlFor="otherIncome" className="input-label font-bold text-slate-600">Indiquez les autres revenus salariés de votre foyer<span className="asterisk">*</span></label>
                    <input className="input_contact" id="otherIncome" onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) setUserOtherAnnualSalariedIncome(Number(e.target.value))
                    }} value={userOtherAnnualSalariedIncome} placeholder="montant" />
                        */}
                    <br />
                    <br />

                    <label htmlFor="revenueType" className="input-label font-bold text-slate-600 cursor-pointer">
                        Indiquez les autres revenus imposables du foyer<span className="asterisk">*</span>

                    </label>

                    <Select
                        isMulti
                        name="revenues"
                        options={colourOptions}
                        styles={customStyles}
                        classNamePrefix="custom-select"
                        onChange={handleRevenueSelect}
                        value={colourOptions.filter(option =>
                            selectedRevenues.some(revenue => removeAccents(revenue.type) === removeAccents(option.label))
                        )}
                    />

                    {selectedRevenues.map((revenue, index) => (
                        <div key={index}>
                            <label htmlFor={`revenueAmount-${index}`} className="input-label font-bold text-slate-600">
                                Montant pour {revenue.type}
                            </label>
                            <input
                                type="number"
                                id={`revenueAmount-${index}`}
                                className="input_contact"
                                placeholder={`Montant pour ${revenue.type}`}
                                onChange={(e) => handleRevenueAmountChange(index, e.target.value)}
                                value={revenue.amount}
                            />
                        </div>
                    ))}


                    <br />
                    <br />

                    <p className="font-medium text-red-500 text-2xl">{errorMessage}</p>

                    <div>
                        <ShimmerButton onClick={(event) => handleSubmit(event)}
                            className={`bg-purple-500 hover:bg-blue-600 text-white font-bold py-4 px-4 mt-10 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 mx-auto block mb-16 ${loading ? 'opacity-50' : ''}`}
                            disabled={loading && errorMessage === ""}>
                            {loading ? 'Chargement...' : 'Voir le résultat'}
                        </ShimmerButton>
                        {loading && (
                            <div className="loading-overlay">
                                <div className="loader-container">
                                    <div className="loader">
                                        <div id="first">
                                            <div id="second">
                                                <div id="third">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="loading-text">Nous recherchons la meilleure solution à votre projet</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {open && (
                        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(245, 245, 245, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}>
                            <div style={{ maxWidth: '80%', margin: '0 auto', backgroundColor: '#F5F5F5', padding: '20px', maxHeight: '80vh', overflowY: 'auto' }}>
                                <Button variant="contained" onClick={handleClose} style={{ float: 'right' }}>Fermer</Button>
                                {loading ? (
                                    <div className="loading-overlay">
                                        <div className="loader-container">
                                            <div className="loader">
                                                <div id="first">
                                                    <div id="second">
                                                        <div id="third"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="loading-text">Nous recherchons la meilleure solution à votre projet</p>
                                        </div>
                                    </div>
                                ) : null}
                                {responseData && (
                                    <DataTable data={responseData} userData={userData} />
                                )}
                            </div>
                        </div>
                    )}
                </form>
            </div>

        </>
    );
};

export default Forms;