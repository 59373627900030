export default function InputsVehicleVP({ index, value1, setValue1, value2, setValue2, value3, setValue3 }: { index: number, value1: Number, setValue1: React.Dispatch<React.SetStateAction<number>>, value2: Number, setValue2: React.Dispatch<React.SetStateAction<number>>, value3: Number, setValue3: React.Dispatch<React.SetStateAction<number>> }) {
    return (
        <div key={`vehicle${index}`}>
            <label htmlFor={`priceVP${index}`} className="input-label font-bold text-slate-600">
                Indiquez le prix du véhicule de tourisme {index + 1}
            </label>
            <input
                className="input_contact"
                type="text"
                id={`priceVP${index}`}
                placeholder={`Indiquez le prix du véhicule de tourisme ${index + 1}`}
                onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                        setValue1(Number(e.target.value))
                    }
                }}
                value={value1.toString()}
                required
            />

            <br />
            <br />
            

            <label htmlFor={`co2VP${index}`} className="input-label font-bold text-slate-600">
                Indiquez le taux d'émission de CO2 du véhicule de tourisme {index + 1}
            </label>
            <input
                className="input_contact"
                type="text"
                id={`co2VP${index}`}
                placeholder={`Indiquez le taux d'émission de CO2 du véhicule de tourisme ${index + 1}`}
                onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                        setValue2(Number(e.target.value))
                    }
                }}
                value={value2.toString()}
                required
            />

            <br />
            <br />
           {/*
            <label htmlFor={`dateVP${index}`} className="input-label font-bold text-slate-600">
                Indiquez la date d'immatriculation du véhicule de tourisme {index + 1}
            </label>
            <input
                className="input_contact"
                type="date"
                id={`dateVP${index}`}
                placeholder={`Indiquez la date d'immatriculation du véhicule de tourisme ${index + 1}`}
                onChange={(e) => {
                    // TODO: A FAIRE
                }}
                required
            />
            */}
        </div>
    )
}
