import { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ShimmerButton from "../components/magicui/shimmer-button";
import "../styles/loader.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Arrow } from "../components/arrow";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51Pb5rcHnQLCbZW4Yq03X1C19tZqlBX4uVm0DO5GQ18lpOjYKhWQeo04xLoiwP4Zv91YUJacL9F1LEtelAcdj0MRm008TM7yz7B"
);

//const stripePromise = loadStripe('pk_test_51M5XfmHtRheYJmNr5Jtn8vwuNv6vqd7C3YpZyw9dOCFF7OOa1bBib3P126COAbYy6LtV9UGjmlmNo8CCTEjFCwuv00cdOSA8lZ');

ReactGA.initialize("G-78TF44GTX6");
ReactGA.send({ hitType: "pageview", page: "window.location.pathname" });

const Status = () => {
  const [formData, setFormData] = useState({
    email: "",
    "Votre forme juridique": "",
    "CODE POSTALE SIEGE SOCIAL": "",
    "Nombre d'associé": "",
    "Votre objet social complet": "",
    "Votre dénomination sociale": "",
    "Date de début d'activité": "",
    "Date de clôture de l'exercice comptable": "",
    "ADRESSE SIEGE SOCIAL N° ET RUE": "",
    "VILLE SIEGE SOCIAL": "",
    "MONTANT DE CAPITAL": "",
    "Nombre de parts sociales ou actions": "",
    "Nom de LA BANQUE ou est / ou sera déposé le capital": "",
    "Date de clôture du premier exercice comptable": "",
    "Indiquez la civilité de l'associé 1": "",
    "Indiquez le nom de naissance de l'associé 1": "",
    "Indiquez le nom d'usage de l'associé 1": "",
    "Indiquez le prénom de l'associé 1": "",
    "Indiquez le deuxième prénom de l'associé 1": "",
    "Indiquez la date de naissance de l'associé 1": "",
    "Indiquez le lieu de naissance de l'associé 1": "",
    "Indiquez le département de naissance de l'associé 1": "",
    "Indiquez la nationalité de l'associé 1": "",
    "Indiquez l'adresse postale de l'associé 1 (N° de rue nom de rue)": "",
    "Indiquez l'adresse postale de l'associé 1 (VILLE)": "",
    "Indiquez l'adresse postale de l'associé 1 (CODE POSTAL)": "",
    "Indiquez le statut matrimonial de l'associé 1": "",
    "Indiquez le pourcentage de détention de l'associé 1": "",
    "Indiquez si l'associé 1 est également gérant": "",
    "Indiquez la civilité de l'associé 2": "",
    "Indiquez le nom de naissance de l'associé 2": "",
    "Indiquez le nom d'usage de l'associé 2": "",
    "Indiquez le prénom de l'associé 2": "",
    "Indiquez le deuxième prénom de l'associé 2": "",
    "Indiquez la date de naissance de l'associé 2": "",
    "Indiquez le lieu de naissance de l'associé 2": "",
    "Indiquez le département de naissance de l'associé 2": "",
    "Indiquez la nationalité de l'associé 2": "",
    "Indiquez l'adresse postale de l'associé 2 (N° de rue nom de rue)": "",
    "Indiquez l'adresse postale de l'associé 2 (VILLE)": "",
    "Indiquez l'adresse postale de l'associé 2 (CODE POSTAL)": "",
    "Indiquez le statut matrimonial de l'associé 2": "",
    "Indiquez le pourcentage de détention de l'associé 2": "",
    "Indiquez si l'associé 2 est également gérant": "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const imagePaths = [
    "/img/STATUTS a utiliser pour apercu-01.png",
    "/img/STATUTS a utiliser pour apercu-02.png",
    "/img/STATUTS a utiliser pour apercu-03.png",
    "/img/STATUTS a utiliser pour apercu-04.png",
    "/img/STATUTS a utiliser pour apercu-05.png",
    "/img/STATUTS a utiliser pour apercu-06.png",
    "/img/STATUTS a utiliser pour apercu-07.png",
    "/img/STATUTS a utiliser pour apercu-08.png",
    "/img/STATUTS a utiliser pour apercu-09.png",
    "/img/STATUTS a utiliser pour apercu-10.png",
    "/img/STATUTS a utiliser pour apercu-11.png",
    "/img/STATUTS a utiliser pour apercu-12.png",
    "/img/STATUTS a utiliser pour apercu-13.png",
    "/img/STATUTS a utiliser pour apercu-14.png",
    "/img/STATUTS a utiliser pour apercu-15.png",
    "/img/STATUTS a utiliser pour apercu-16.png",
    "/img/STATUTS a utiliser pour apercu-17.png",
    "/img/STATUTS a utiliser pour apercu-18.png",
    "/img/STATUTS a utiliser pour apercu-19.png",
    "/img/STATUTS a utiliser pour apercu-20.png",
    "/img/STATUTS a utiliser pour apercu-21.png",
    "/img/STATUTS a utiliser pour apercu-22.png",
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const { scrollYProgress } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isMobile = width < 768;
      setIsMobile(isMobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("session_id")) {
      setIsPaymentSuccess(true);
    }
  }, [location.search]);

  const isFormValid = () => {
    const requiredFields = [
      "email",
      "Votre forme juridique",
      "CODE POSTALE SIEGE SOCIAL",
      "Votre objet social complet",
      "Votre dénomination sociale",
      "Date de début d'activité",
      "Date de clôture de l'exercice comptable",
      "ADRESSE SIEGE SOCIAL N° ET RUE",
      "VILLE SIEGE SOCIAL",
      "MONTANT DE CAPITAL",
      "Nombre de parts sociales ou actions",
      "Nom de LA BANQUE ou est / ou sera déposé le capital",
      "Date de clôture du premier exercice comptable",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    const requiredAssociateFields = (associateNumber) => [
      `Indiquez la civilité de l'associé ${associateNumber}`,
      `Indiquez le nom de naissance de l'associé ${associateNumber}`,
      `Indiquez le nom d'usage de l'associé ${associateNumber}`,
      `Indiquez le prénom de l'associé ${associateNumber}`,
      `Indiquez le deuxième prénom de l'associé ${associateNumber}`,
      `Indiquez la date de naissance de l'associé ${associateNumber}`,
      `Indiquez le lieu de naissance de l'associé ${associateNumber}`,
      `Indiquez le département de naissance de l'associé ${associateNumber}`,
      `Indiquez la nationalité de l'associé ${associateNumber}`,
      `Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`,
      `Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`,
      `Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`,
      `Indiquez le statut matrimonial de l'associé ${associateNumber}`,
      `Indiquez le pourcentage de détention de l'associé ${associateNumber}`,
      `Indiquez si l'associé ${associateNumber} est également gérant`,
    ];

    const numberOfAssociates = formData["Nombre d'associé"];
    for (let i = 1; i <= numberOfAssociates; i++) {
      for (const field of requiredAssociateFields(i)) {
        if (!formData[field]) {
          return false;
        }
      }
    }

    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Si le champ concerne un pourcentage de détention
    if (name.includes("pourcentage de détention")) {
      let newValue = value.replace(/[^0-9,]/g, ""); // Autoriser uniquement les chiffres et les virgules
  
      // Si le dernier caractère n'est pas un % et qu'il y a des chiffres, on l'ajoute
      if (newValue !== "" && !newValue.includes("%")) {
        newValue += "%";
      }
  
      // Si l'utilisateur supprime tout, retirez également le %
      if (newValue === "%") {
        newValue = "";
      }
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  
  
    
  
  const handleOpenModal = () => {
    if (isFormValid()) {
      setIsModalOpen(true);
      setErrorMessage("");
    } else {  
      setErrorMessage("Veuillez remplir tous les champs requis.");
    }
  };

  const handlePayment = async () => {
    const stripe = await stripePromise;
    try {
      const response = await axios.post(
        "https://fbconseil-c706cd2b1837.herokuapp.com/stripe-status/create-checkout-session-status",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { id: sessionId } = response.data;


      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        setErrorMessage(result.error.message);
      }
    } catch (error) {
      setErrorMessage("Error creating checkout session: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    try {
      //const response = await axios.post( "https://fbconseil-c706cd2b1837.herokuapp.com/api/getStatus", formData,
      const response = await axios.post(
        "https://fbconseil-c706cd2b1837.herokuapp.com/api/getStatus",
        formData,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setErrorMessage("Données soumises avec succès");
      } else {
        setErrorMessage("Erreur lors de la soumission des données");
      }
    } catch (error) {
      setErrorMessage(
        "Une erreur est survenue lors de la soumission des données"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDateInput = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Supprime tout sauf les chiffres
    let day = value.slice(0, 2);
    let month = value.slice(2, 4);

    if (day.length === 2 && parseInt(day) > 31) {
      day = "31";
    }

    if (month.length === 2 && parseInt(month) > 12) {
      month = "12";
    }

    if (value.length >= 2) {
      value = day + (month.length > 0 ? "/" + month : "");
    } else {
      value = day;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      "Date de clôture de l'exercice comptable": value,
    }));
  };

  const handleKeyDown = (e) => {
    const currentDate = formData["Date de clôture de l'exercice comptable"];

    if (e.key === "Backspace") {
      e.preventDefault();

      if (currentDate.length === 3) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          "Date de clôture de l'exercice comptable": currentDate.slice(0, 2),
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          "Date de clôture de l'exercice comptable": currentDate.slice(0, -1),
        }));
      }
    }
  };

  const renderAssociateFields = (index) => {
    const associateNumber = index + 1;
    return (
      <div key={associateNumber}>
        <div className="input-container">
          <label
            htmlFor={`Indiquez la civilité de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Civilité de l'associé {associateNumber}{" "}
            <span className="asterisk">*</span>
          </label>
          <select
            id={`Indiquez la civilité de l'associé ${associateNumber}`}
            name={`Indiquez la civilité de l'associé ${associateNumber}`}
            className="input_contact"
            onChange={handleChange}
            value={
              formData[`Indiquez la civilité de l'associé ${associateNumber}`]
            }
            required
          >
            <option value="">Sélectionner la civilité</option>
            <option value="Madame">MADAME</option>
            <option value="Monsieur">MONSIEUR</option>
          </select>
        </div>

        <br />

        <div className="input-container">
          <label
            htmlFor={`Indiquez le nom de naissance de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
           Nom de naissance de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le nom de naissance de l'associé ${associateNumber}`}
            name={`Indiquez le nom de naissance de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Nom de naissance de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez le nom de naissance de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le nom d'usage de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Nom d'usage de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le nom d'usage de l'associé ${associateNumber}`}
            name={`Indiquez le nom d'usage de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Nom d'usage de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez le nom d'usage de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le prénom de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Prénom de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le prénom de l'associé ${associateNumber}`}
            name={`Indiquez le prénom de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Prénom de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[`Indiquez le prénom de l'associé ${associateNumber}`]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le deuxième prénom de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
           Deuxième prénom de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le deuxième prénom de l'associé ${associateNumber}`}
            name={`Indiquez le deuxième prénom de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Deuxième prénom de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez le deuxième prénom de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez la date de naissance de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Date de naissance de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="date"
            id={`Indiquez la date de naissance de l'associé ${associateNumber}`}
            name={`Indiquez la date de naissance de l'associé ${associateNumber}`}
            className="input_contact"
            onChange={handleChange}
            value={
              formData[
                `Indiquez la date de naissance de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le lieu de naissance de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Lieu de naissance de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le lieu de naissance de l'associé ${associateNumber}`}
            name={`Indiquez le lieu de naissance de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Lieu de naissance de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez le lieu de naissance de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le département de naissance de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Département de naissance de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez le département de naissance de l'associé ${associateNumber}`}
            name={`Indiquez le département de naissance de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Département de naissance de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez le département de naissance de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez la nationalité de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
           Nationalité de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez la nationalité de l'associé ${associateNumber}`}
            name={`Indiquez la nationalité de l'associé ${associateNumber}`}
            className="input_contact"
            placeholder={`Nationalité de l'associé ${associateNumber}`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez la nationalité de l'associé ${associateNumber}`
              ]
            }
            required
          />
        </div>
        <br />
        {/* Nouveau champ : Numéro de rue et nom de rue */}
        <div className="input-container">
          <label
            htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`}
            className="input-label font-bold text-gray-600"
          >
            Adresse postale de l'associé {associateNumber} (N° de rue,
            Nom de rue)<span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`}
            name={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`}
            className="input_contact"
            placeholder={`Numéro et nom de rue`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`
              ]
            }
            required
          />
        </div>
        <br />
        {/* Nouveau champ : Ville */}
        <div className="input-container">
          <label
            htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`}
            className="input-label font-bold text-gray-600"
          >
            Adresse postale de l'associé {associateNumber} (Ville)
            <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`}
            name={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`}
            className="input_contact"
            placeholder={`Ville`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`
              ]
            }
            required
          />
        </div>
        <br />
        {/* Nouveau champ : Code postal */}
        <div className="input-container">
          <label
            htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`}
            className="input-label font-bold text-gray-600"
          >
            Adresse postale de l'associé {associateNumber} (Code
            Postal)<span className="asterisk">*</span>
          </label>
          <input
            type="text"
            id={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`}
            name={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`}
            className="input_contact"
            placeholder={`Code postal`}
            onChange={handleChange}
            value={
              formData[
                `Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`
              ]
            }
            required
          />
        </div>
        <br />

       
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le statut matrimonial de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Statut matrimonial de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <select
            id={`Indiquez le statut matrimonial de l'associé ${associateNumber}`}
            name={`Indiquez le statut matrimonial de l'associé ${associateNumber}`}
            className="input_contact"
            onChange={handleChange}
            value={
              formData[
                `Indiquez le statut matrimonial de l'associé ${associateNumber}`
              ]
            }
            required
          >
            <option value="">Sélectionnez un statut</option>
            <option value="Célibataire">Célibataire</option>
            <option value="Marié">Marié</option>
            <option value="Divorcé">Divorcé</option>
          </select>
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`}
            className="input-label font-bold text-gray-600"
          >
            Pourcentage de détention de l'associé {associateNumber}
            <span className="asterisk">*</span>
          </label>
          <input
  type="text"
  id={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`}
  name={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`}
  className="input_contact"
  placeholder={`Pourcentage de détention de l'associé ${associateNumber}`}
  onChange={handleChange}
  value={
    formData[
      `Indiquez le pourcentage de détention de l'associé ${associateNumber}`
    ]
  }
  required
/>
        </div>
        <br />
        <div className="input-container">
          <label
            htmlFor={`Indiquez si l'associé ${associateNumber} est également gérant`}
            className="input-label font-bold text-gray-600"
          >
            L'associé {associateNumber} est gérant ?
            <span className="asterisk">*</span>
          </label>
          <select
            id={`Indiquez si l'associé ${associateNumber} est également gérant`}
            name={`Indiquez si l'associé ${associateNumber} est également gérant`}
            className="input_contact"
            onChange={handleChange}
            value={
              formData[
                `Indiquez si l'associé ${associateNumber} est également gérant`
              ]
            }
            required
          >
            <option value="">Sélectionnez une option</option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </div>
      </div>
    );
  };

  return (
    <>
      <Arrow />
      <div
        className="min-h-screen flex flex-col items-center justify-center"
        style={{ backgroundColor: "var(--background-color)" }}
      >
        <div className="text-left px-4 sm:px-6 lg:px-8 mb-10 mt-12">
          <h1 className="text-3xl mt-20 font-bold text-black">
            Vous souhaitez obtenir VOS STATUTS ?
          </h1>
          <p className="font-semibold mt-6">
            Afin de vous accompagner jusqu’au bout dans le lancement de votre
            projet, vous pouvez également obtenir les statuts de votre société
            au tarif de 50€ HT seulement !
          </p>
          <p className="font-semibold mt-6">
            Rien de plus simple, complétez simplement le formulaire:
          </p>
        </div>

        {isMobile && (
          <motion.div
            className="progress-bar"
            style={{
              scaleX,
              originX: 0,
              scaleY: 2,
              backgroundColor: "#3f9bf1",
            }}
          />
        )}
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-lg space-y-6 overflow-hidden"
        >
          <div className="input-container">
            <label
              htmlFor="adresse mail"
              className="input-label font-bold text-gray-600"
            >
              Adresse mail<span className="asterisk">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="input_contact"
              placeholder="Adresse mail"
              onChange={handleChange}
              value={formData["email"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Votre forme juridique"
              className="input-label font-bold text-gray-600"
            >
              Votre forme juridique<span className="asterisk">*</span>
            </label>
            <select
              id="Votre forme juridique"
              name="Votre forme juridique"
              className="input_contact"
              onChange={handleChange}
              value={formData["Votre forme juridique"]}
              required
            >
              <option value="" disabled>
                Sélectionnez une option
              </option>
              <option value="SARL">SARL</option>
              <option value="EURL">EURL</option>
              <option value="SAS">SAS</option>
              <option value="SASU">SASU</option>
            </select>
          </div>

          <div className="input-container">
            <label
              htmlFor="Nombre d'associé"
              className="input-label font-bold text-gray-600"
            >
              Nombre d'associé<span className="asterisk">*</span>
            </label>
            <select
              id="Nombre d'associé"
              name="Nombre d'associé"
              className="input_contact"
              onChange={handleChange}
              value={formData["Nombre d'associé"]}
              required
            >
              <option value="">Sélectionnez</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>

          {formData["Nombre d'associé"] &&
            Array.from({ length: Number(formData["Nombre d'associé"]) }).map(
              (_, index) => renderAssociateFields(index)
            )}

          <div className="input-container">
            <label
              htmlFor="Votre objet social complet"
              className="input-label font-bold text-gray-600"
            >
              Objet social complet<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="Votre objet social complet"
              name="Votre objet social complet"
              className="input_contact"
              placeholder="Objet social complet"
              onChange={handleChange}
              value={formData["Votre objet social complet"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Votre dénomination sociale"
              className="input-label font-bold text-gray-600"
            >
              Dénomination sociale<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="Votre dénomination sociale"
              name="Votre dénomination sociale"
              className="input_contact"
              placeholder="Dénomination sociale"
              onChange={handleChange}
              value={formData["Votre dénomination sociale"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Date de début d'activité"
              className="input-label font-bold text-gray-600"
            >
              Date de début d'activité<span className="asterisk">*</span>
            </label>
            <input
              type="date"
              id="Date de début d'activité"
              name="Date de début d'activité"
              className="input_contact"
              onChange={handleChange}
              value={formData["Date de début d'activité"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Date de clôture de l'exercice comptable"
              className="input-label font-bold text-gray-600"
            >
              Date de clôture de l'exercice comptable (JJ/MM)
              <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="Date de clôture de l'exercice comptable"
              name="Date de clôture de l'exercice comptable"
              className="input_contact"
              onChange={handleDateInput}
              onKeyDown={handleKeyDown}
              value={formData["Date de clôture de l'exercice comptable"]}
              placeholder="JJ/MM"
              maxLength="5"
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="ADRESSE SIEGE SOCIAL N° ET RUE"
              className="input-label font-bold text-gray-600"
            >
              Adresse siège social n° et rue<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="ADRESSE SIEGE SOCIAL N° ET RUE"
              name="ADRESSE SIEGE SOCIAL N° ET RUE"
              className="input_contact"
              placeholder="Adresse siège social n° et rue"
              onChange={handleChange}
              value={formData["ADRESSE SIEGE SOCIAL N° ET RUE"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="CODE POSTALE SIEGE SOCIAL"
              className="input-label font-bold text-gray-600"
            >
              Code postale siège social<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="CODE POSTALE SIEGE SOCIAL"
              name="CODE POSTALE SIEGE SOCIAL"
              className="input_contact"
              placeholder="Code postale siège social"
              onChange={handleChange}
              value={formData["CODE POSTALE SIEGE SOCIAL"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="VILLE SIEGE SOCIAL"
              className="input-label font-bold text-gray-600"
            >
              Ville siège social<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="VILLE SIEGE SOCIAL"
              name="VILLE SIEGE SOCIAL"
              className="input_contact"
              placeholder="Ville siège sociale"
              onChange={handleChange}
              value={formData["VILLE SIEGE SOCIAL"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="MONTANT DE CAPITAL"
              className="input-label font-bold text-gray-600"
            >
              Montant de capital<span className="asterisk">*</span>
            </label>
            <input
              type="number"
              id="MONTANT DE CAPITAL"
              name="MONTANT DE CAPITAL"
              className="input_contact"
              placeholder="Montant de capital"
              onChange={handleChange}
              value={formData["MONTANT DE CAPITAL"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Nombre de parts sociales ou actions"
              className="input-label font-bold text-gray-600"
            >
              Nombre de parts sociales ou actions
              <span className="asterisk">*</span>
            </label>
            <input
              type="number"
              id="Nombre de parts sociales ou actions"
              name="Nombre de parts sociales ou actions"
              className="input_contact"
              placeholder="Nombre de parts sociales ou actions"
              onChange={handleChange}
              value={formData["Nombre de parts sociales ou actions"]}
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Nom de LA BANQUE ou est / ou sera déposé le capital"
              className="input-label font-bold text-gray-600"
            >
              Nom de LA BANQUE ou est / ou sera déposé le capital
              <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              id="Nom de LA BANQUE ou est / ou sera déposé le capital"
              name="Nom de LA BANQUE ou est / ou sera déposé le capital"
              className="input_contact"
              placeholder="Nom de LA BANQUE ou est / ou sera déposé le capital"
              onChange={handleChange}
              value={
                formData["Nom de LA BANQUE ou est / ou sera déposé le capital"]
              }
              required
            />
          </div>

          <div className="input-container">
            <label
              htmlFor="Date de clôture du premier exercice comptable"
              className="input-label font-bold text-gray-600"
            >
              Date de clôture du premier exercice comptable
              <span className="asterisk">*</span>
            </label>
            <input
              type="date"
              id="Date de clôture du premier exercice comptable"
              name="Date de clôture du premier exercice comptable"
              className="input_contact"
              onChange={handleChange}
              value={formData["Date de clôture du premier exercice comptable"]}
              required
            />
          </div>

          <ShimmerButton
            type="button"
            className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-4 px-4 mt-10 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 mx-auto block mb-16"
            onClick={handleOpenModal}
            disabled={!isFormValid() || loading}
          >
            {loading ? "Chargement..." : "Générer mes statuts"}
          </ShimmerButton>

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
              <div className="bg-white border border-black rounded-lg p-4 relative flex lg:flex-row flex-col-reverse max-w-2xl w-full z-10">
                <button
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                  onClick={() => setIsModalOpen(false)}
                >
                  &times;
                </button>
                <div className="lg:w-1/2 w-full flex flex-col items-start mt-8 lg:mt-0">
                  <h2 className="text-lg font-bold mb-2 mt-28">
                    Vos statuts sont désormais disponibles !
                  </h2>
                  <p className="mt-2">
                    J'obtiens mes STATUTS pour 50€{" "}
                    <span className="text-sm text-gray-600">HT</span>
                  </p>
                  <ShimmerButton
                    type="button"
                    className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                    onClick={handlePayment}
                    disabled={loading}
                  >
                    {loading ? "Chargement..." : "Payer"}
                  </ShimmerButton>
                </div>
                <div className="lg:w-1/2 w-full flex justify-center lg:justify-end mt-4 lg:mt-0">
                  <div
                    className="overflow-y-auto max-h-96 w-full"
                    style={{ maxHeight: "500px" }}
                  >
                    {imagePaths.map((path, index) => (
                      <img
                        key={index}
                        src={path}
                        alt={`Page ${index + 1}`}
                        className="w-full h-auto mb-4"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isPaymentSuccess && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
              <div className="bg-white border border-black rounded-lg p-4 relative max-w-2xl w-full z-10">
                <button
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                  onClick={() => {
                    setIsPaymentSuccess(false);
                    navigate("/"); // Redirigez vers la page d'accueil après avoir fermé la modal
                  }}
                >
                  &times;
                </button>
                <div className="flex flex-col items-center">
                  <h2 className="text-lg font-bold mb-2">
                    Merci pour votre confiance !
                  </h2>
                  <p>
                    Vous allez recevoir un mail (pdf) avec vos statuts,
                    n'oubliez pas de vérifier vos spams.
                  </p>
                </div>
              </div>
            </div>
          )}

          {errorMessage && (
            <p className="text-red-500 text-center">{errorMessage}</p>
          )}
        </form>
      </div>
    </>
  );
};

export default Status;
