import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ShimmerButton from '../components/magicui/shimmer-button';

export const Previsionnel = () => {
    const [isOpenLeft, setIsOpenLeft] = useState(false);
    const [isOpenRight, setIsOpenRight] = useState(false);
    const navigate = useNavigate();

    const toggleOpenLeft = () => {
        setIsOpenLeft(!isOpenLeft);
    };

    const toggleOpenRight = () => {
        setIsOpenRight(!isOpenRight);
    };

    const ChevronDown = () => (
        <svg width={24} height={24} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m19 9-7 7-7-7" />
        </svg>
    );

    const ChevronUp = () => (
        <svg width={24} height={24} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M17.648 15.248a1.2 1.2 0 0 1-1.696 0L12 11.297l-3.952 3.951a1.2 1.2 0 0 1-1.696-1.696l4.8-4.8a1.2 1.2 0 0 1 0 1.696Z" clipRule="evenodd" />
        </svg>
    );

    return (
        <>
            <div className='min-h-screen pt-6 sm:pt-12 md:pt-24'>
                <div className="max-w-6xl mx-auto px-4 text-left flex flex-wrap mt-4">
                    <div className="w-full md:w-1/2 pr-2 md:pr-4">
                        <div className="w-full flex items-center mb-4 cursor-pointer" onClick={toggleOpenLeft}>
                            <h1 className="text-sm sm:text-lg md:text-xl font-bold text-black flex-grow">
                                Pourquoi avoir besoin d’un budget prévisionnel ?
                            </h1>
                            <div className="flex-shrink-0 ml-2">
                                {isOpenLeft ? <ChevronUp /> : <ChevronDown />}
                            </div>
                        </div>
                        <AnimatePresence>
                            {isOpenLeft && (
                                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                                    <div className="mt-4">
                                        <div className="mb-4 flex items-start">
                                            <span className="text-[#1D63FF]">🔵</span>
                                            <span className="text-sm sm:text-base md:text-lg mb-2 sm:mb-4 md:mb-6 leading-relaxed  ml-2">
                                                Un budget prévisionnel est conseillé lors d’une création d’entreprise afin d'obtenir
                                                <strong> une prévision des données chiffrées pour les premières années d'activité </strong>
                                                et ainsi, s'assurer de la <strong>viabilité économique du projet</strong>.
                                            </span>
                                        </div>
                                        <div className="mb-4 flex items-start">
                                            <span className="text-[#1D63FF]">🔵</span>
                                            <span className="text-sm sm:text-base md:text-lg mb-2 sm:mb-4 md:mb-6 leading-relaxed  ml-2">
                                                Il est aussi demandé par certains organismes, comme les banques notamment, afin d'obtenir
                                                des financements pour de l'investissement dans du matériel professionnel,
                                                informatique,
                                                stock de départ,
                                                avance de trésorerie etc…
                                            </span>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <div className="w-full md:w-1/2 pr-2 md:pr-4">
                        <div className="w-full flex items-center mb-4 cursor-pointer" onClick={toggleOpenRight}>
                            <h1 className="text-sm sm:text-lg md:text-xl font-bold text-black flex-grow">
                            Que contient votre budget prévisionnel ?
                            </h1>
                            <div className="flex-shrink-0 ml-2">
                                {isOpenRight ? <ChevronUp /> : <ChevronDown />}
                            </div>
                        </div>
                        <AnimatePresence>
                            {isOpenRight && (
                                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className="overflow-hidden w-full">
                                    <div className="mt-4">
                                        <div className="mb-4 flex items-start">
                                            <span className="text-[#1D63FF]">🔵</span>
                                            <div className="ml-2">
                                                <strong>Une présentation détaillée de votre projet</strong>
                                                <br />
                                                Celui-ci reprenant tous les éléments de votre projet avec l’activité choisie, le montant du capital, le montant du chiffre d'affaires et son évolution, le détail des investissements, la présence de salariés, etc…
                                                <br />
                                                Cette présentation permet d’indiquer clairement les éléments clés de votre projet de création.
                                            </div>
                                        </div>
                                        <div className="mb-4 flex items-start">
                                            <span className="text-[#1D63FF]">🔵</span>
                                            <div className="ml-2">
                                                <strong>Un tableau de financement</strong>
                                                <br />
                                                Il fait apparaître les <strong>besoins de trésorerie</strong> et les <strong>ressources</strong> au lancement de l'activité en mettant en évidence le montant du capital, des apports, des emprunts, des investissements, de la trésorerie avec le fond de roulement de départ etc….
                                                <br />
                                                Ce tableau de financement permet de visualiser rapidement le montant de la <strong>trésorerie de départ</strong> et de vérifier la <strong>présence de fonds nécessaires</strong> au bon démarrage du projet.
                                            </div>
                                        </div>
                                        <div className="mb-4 flex items-start">
                                            <span className="text-[#1D63FF]">🔵</span>
                                            <div className="ml-2">
                                                <strong>Un compte de résultat à 3 ans</strong>
                                                <br />
                                                Un compte de résultat à 3 ans, permettant de <strong>récapituler</strong> l’ensemble des <strong>dépenses</strong> et des <strong>recettes</strong> et faire ressortir le <strong>résultat net comptable</strong> et son évolution sur les trois premières années.
                                            </div>
                                        </div>
                                    </div>

                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>

                <div className="max-w-6xl mx-auto px-4 text-left mt-12 flex flex-col md:flex-row md:items-end">
                    <div className="flex items-end">
                        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-2 sm:mb-4 md:mb-6 text-black mr-20">
                            Comment obtenir mon budget prévisionnel ?
                        </h2>
                    </div>
                    <img src="/img/illustration-bp.jpg" alt="Image" className="w-[7rem] h-[7rem] mt-4 md:mt-0" />
                </div>





                <div className="max-w-6xl mx-auto px-4 text-left">
                    <p className="text-sm sm:text-base md:text-xl font-semibold mb-2 sm:mb-4 md:mb-6 leading-relaxed text-gray-600">
                        Nous vous proposons deux formules:
                    </p>
                    <div className="mb-4 flex items-start">
                        <span className="text-[#1D63FF]">🔵</span>
                        <div className="ml-2">
                            <strong>Un budget Prévisionnel instantané,</strong> tiré directement de votre simulation réalisée en ligne, avec la forme juridique la plus adaptée à votre projet, au tarif de <strong>50€ HT seulement</strong> !
                            <br />
                            <span className="text-sm sm:text-base md:text-lg mb-2 sm:mb-4 md:mb-6 leading-relaxed text-gray-600">
                                (Il vous sera envoyé au format PDF sur votre boite mail dès le paiement réalisé).
                            </span>
                            <p className="text-sm sm:text-base md:text-lg mt-4 leading-relaxed text-gray-600">
                                A savoir : notre budget prévisionnel issu de votre SIMULATION est très détaillé. Il tient compte de l’activité choisie, du niveau de chiffre d’affaires enregistré, de l’utilisation d’un véhicule pro ou perso, du niveau de rémunération, de l’impôts sur société avec les mises à jours fiscales…
                            </p>
                            <div className="flex justify-center">
                                <ShimmerButton
                                    onClick={() => navigate('/Form')}
                                    className="bg-[#1D63FF] text-white py-2 px-4 rounded mt-4"
                                >
                                    Démarrer ma simulation
                                </ShimmerButton>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 flex items-start">
                        <span className="text-[#1D63FF]">🔵</span>
                        <div className="ml-2">
                            <strong>Un budget prévisionnel 100% personnalisé</strong> comprend un RDV avec un expert en création au tarif de <strong>120€ HT</strong>.
                            <br />
                            <span className="text-sm sm:text-base md:text-lg mb-2 sm:mb-4 md:mb-6 leading-relaxed text-gray-600">
                                Cette option est préconisée si vous avez des particularités personnelles, notamment la présence de l’aide au retour à l'emploi (ARE), une double activité, retraité…
                                <br />
                                Cette option vous permet également d’y voir plus clair grâce à la possibilité de poser toutes vos questions à un professionnel en création, de comprendre pourquoi cette forme juridique est mieux qu’une autre, de pouvoir tout simplement discuter de votre projet.
                            </span>
                            <div className="flex justify-center">
                                <ShimmerButton
                                    onClick={() => window.open('https://calendly.com/fbsimulateurs/30min', '_blank')}
                                    className="bg-[#1D63FF] text-white py-2 px-4 rounded mt-4"
                                >
                                    Prendre mon RDV
                                </ShimmerButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
